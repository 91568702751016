export const REDUX_STORE_NAME = "EH65X19P";

export const DARK_PINK_COLOR = "#ff0a90";
export const LIGHT_PINK_COLOR = "#ffe6f4";
export const fetchSessionIgnoreList = ["/profile/view", "/profile"];

export const PAGE_SIZE = 20;

export const IS_DEVELOPMENT = process.env.REACT_APP_EH_ENV === "development";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";

export const POST_MEDIA_UPLOAD_LIMIT = 4;

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const SAMPLE_CSV_DOWNLOAD =
  "https://eh-public-assets.s3.us-west-2.amazonaws.com/crm-contact-sample.csv";
/**
 * Once user cancel sent connection request, he can able to resend again after RESEND_CONNECTION_REQUEST_TIME_LIMIT
 *
 */
export const RESEND_CONNECTION_REQUEST_TIME_LIMIT = 24;

export const CUSTOM_GENDER_OPTIONS = [
  { value: "1", label: `She: "Wish her a happy birthday!"` },
  { value: "2", label: `He: "Wish him a happy birthday!"` },
  { value: "3", label: `They: "Wish them a happy birthday!"` },
];

export const EMAIL_SUBSCRIPTION_OPTIONS = [
  { value: "1", label: "Subscribe" },
  { value: "2", label: "Unsubscribe" }
]

export const companyUserAccessLevel = [
  { label: "System Admin", value: "admin" },
  { label: "Sale & Recruiter", value: "sale_recruiter" },
];

export const SubscriptionStatus = {
  Subscribe: true,
  Unsubscribe: false,
};

/**
 * Use common statuses across the Application, Mainly use for Active and Inactive flag
 *
 */
export const commonStatuses = {
  ACTIVE: {
    id: 1,
    title: "active",
  },
  INACTIVE: {
    id: 2,
    title: "inactive",
  },
};

/* job types */

export const jobTypes = {
  PART_TIME: {
    id: 1,
    title: "Part-time",
    key: "part-time",
  },
  FULL_TIME: {
    id: 2,
    title: "Full-time",
    key: "full-time",
  },
  CONTRACT: {
    id: 3,
    title: "Contract",
    key: "contract",
  },
  INTERNSHIP: {
    id: 4,
    title: "Internship",
    key: "internship",
  },
  ALL: {
    id: 5,
    title: "All",
    key: "all",
  },
};

export const timeDurations = {
  WEEK: {
    id: 1,
    title: "week",
    key: "week",
  },
  MONTH: {
    id: 2,
    title: "month",
    key: "month",
  },
  ALL: {
    id: 3,
    title: "all",
    key: "all",
  },
};

export const WorkStatusType = [
  { label: "US Citizen", value: "US Citizen" },
  { label: "Foreign National", value: "Foreign National" },
  { label: "CW-1", value: "CW-1" },
  { label: "CW-2", value: "CW-2" },
  { label: "E1", value: "E1" },
  { label: "E2", value: "E2" },
  { label: "E2 C", value: "E2 C" },
  { label: "E3", value: "E3" },
  { label: "EAD", value: "EAD" },
  { label: "H-1B", value: "H-1B" },
  { label: "H-1B2", value: "H-1B2" },
  { label: "H3", value: "H3" },
  { label: "I", value: "I" },
  { label: "L-1A", value: "L-1A" },
  { label: "L-1B", value: "L-1B" },
  { label: "L2", value: "L2" },
  { label: "O1", value: "O1" },
  { label: "O2", value: "O2" },
  { label: "O3", value: "O3" },
  { label: "OPT", value: "OPT" },
  { label: "P-1A", value: "P-1A" },
  { label: "P-1B", value: "P-1B" },
  { label: "P3", value: "P3" },
  { label: "P4", value: "P4" },
  { label: "Q1", value: "Q1" },
  { label: "R1", value: "R1" },
  { label: "R2", value: "R2" },
  { label: "TN", value: "TN" },
  { label: "TD", value: "TD" },
  { label: "Green Card", value: "Green Card" },
];

export const TIME_ZONE_LIST = [
  {
    "value": "Africa/Abidjan",
    "label": "(GMT+00:00) Africa/Abidjan"
  },
  {
    "value": "Africa/Accra",
    "label": "(GMT+00:00) Africa/Accra"
  },
  {
    "value": "Africa/Addis_Ababa",
    "label": "(GMT+03:00) Africa/Addis_Ababa"
  },
  {
    "value": "Africa/Algiers",
    "label": "(GMT+01:00) Africa/Algiers"
  },
  {
    "value": "Africa/Asmara",
    "label": "(GMT+03:00) Africa/Asmara"
  },
  {
    "value": "Africa/Asmera",
    "label": "(GMT+03:00) Africa/Asmera"
  },
  {
    "value": "Africa/Bamako",
    "label": "(GMT+00:00) Africa/Bamako"
  },
  {
    "value": "Africa/Bangui",
    "label": "(GMT+01:00) Africa/Bangui"
  },
  {
    "value": "Africa/Banjul",
    "label": "(GMT+00:00) Africa/Banjul"
  },
  {
    "value": "Africa/Bissau",
    "label": "(GMT+00:00) Africa/Bissau"
  },
  {
    "value": "Africa/Blantyre",
    "label": "(GMT+02:00) Africa/Blantyre"
  },
  {
    "value": "Africa/Brazzaville",
    "label": "(GMT+01:00) Africa/Brazzaville"
  },
  {
    "value": "Africa/Bujumbura",
    "label": "(GMT+02:00) Africa/Bujumbura"
  },
  {
    "value": "Africa/Cairo",
    "label": "(GMT+02:00) Africa/Cairo"
  },
  {
    "value": "Africa/Casablanca",
    "label": "(GMT+01:00) Africa/Casablanca"
  },
  {
    "value": "Africa/Ceuta",
    "label": "(GMT+01:00) Africa/Ceuta"
  },
  {
    "value": "Africa/Conakry",
    "label": "(GMT+00:00) Africa/Conakry"
  },
  {
    "value": "Africa/Dakar",
    "label": "(GMT+00:00) Africa/Dakar"
  },
  {
    "value": "Africa/Dar_es_Salaam",
    "label": "(GMT+03:00) Africa/Dar_es_Salaam"
  },
  {
    "value": "Africa/Djibouti",
    "label": "(GMT+03:00) Africa/Djibouti"
  },
  {
    "value": "Africa/Douala",
    "label": "(GMT+01:00) Africa/Douala"
  },
  {
    "value": "Africa/El_Aaiun",
    "label": "(GMT+01:00) Africa/El_Aaiun"
  },
  {
    "value": "Africa/Freetown",
    "label": "(GMT+00:00) Africa/Freetown"
  },
  {
    "value": "Africa/Gaborone",
    "label": "(GMT+02:00) Africa/Gaborone"
  },
  {
    "value": "Africa/Harare",
    "label": "(GMT+02:00) Africa/Harare"
  },
  {
    "value": "Africa/Johannesburg",
    "label": "(GMT+02:00) Africa/Johannesburg"
  },
  {
    "value": "Africa/Juba",
    "label": "(GMT+02:00) Africa/Juba"
  },
  {
    "value": "Africa/Kampala",
    "label": "(GMT+03:00) Africa/Kampala"
  },
  {
    "value": "Africa/Khartoum",
    "label": "(GMT+02:00) Africa/Khartoum"
  },
  {
    "value": "Africa/Kigali",
    "label": "(GMT+02:00) Africa/Kigali"
  },
  {
    "value": "Africa/Kinshasa",
    "label": "(GMT+01:00) Africa/Kinshasa"
  },
  {
    "value": "Africa/Lagos",
    "label": "(GMT+01:00) Africa/Lagos"
  },
  {
    "value": "Africa/Libreville",
    "label": "(GMT+01:00) Africa/Libreville"
  },
  {
    "value": "Africa/Lome",
    "label": "(GMT+00:00) Africa/Lome"
  },
  {
    "value": "Africa/Luanda",
    "label": "(GMT+01:00) Africa/Luanda"
  },
  {
    "value": "Africa/Lubumbashi",
    "label": "(GMT+02:00) Africa/Lubumbashi"
  },
  {
    "value": "Africa/Lusaka",
    "label": "(GMT+02:00) Africa/Lusaka"
  },
  {
    "value": "Africa/Malabo",
    "label": "(GMT+01:00) Africa/Malabo"
  },
  {
    "value": "Africa/Maputo",
    "label": "(GMT+02:00) Africa/Maputo"
  },
  {
    "value": "Africa/Maseru",
    "label": "(GMT+02:00) Africa/Maseru"
  },
  {
    "value": "Africa/Mbabane",
    "label": "(GMT+02:00) Africa/Mbabane"
  },
  {
    "value": "Africa/Mogadishu",
    "label": "(GMT+03:00) Africa/Mogadishu"
  },
  {
    "value": "Africa/Monrovia",
    "label": "(GMT+00:00) Africa/Monrovia"
  },
  {
    "value": "Africa/Nairobi",
    "label": "(GMT+03:00) Africa/Nairobi"
  },
  {
    "value": "Africa/Ndjamena",
    "label": "(GMT+01:00) Africa/Ndjamena"
  },
  {
    "value": "Africa/Niamey",
    "label": "(GMT+01:00) Africa/Niamey"
  },
  {
    "value": "Africa/Nouakchott",
    "label": "(GMT+00:00) Africa/Nouakchott"
  },
  {
    "value": "Africa/Ouagadougou",
    "label": "(GMT+00:00) Africa/Ouagadougou"
  },
  {
    "value": "Africa/Porto-Novo",
    "label": "(GMT+01:00) Africa/Porto-Novo"
  },
  {
    "value": "Africa/Sao_Tome",
    "label": "(GMT+00:00) Africa/Sao_Tome"
  },
  {
    "value": "Africa/Timbuktu",
    "label": "(GMT+00:00) Africa/Timbuktu"
  },
  {
    "value": "Africa/Tripoli",
    "label": "(GMT+02:00) Africa/Tripoli"
  },
  {
    "value": "Africa/Tunis",
    "label": "(GMT+01:00) Africa/Tunis"
  },
  {
    "value": "Africa/Windhoek",
    "label": "(GMT+02:00) Africa/Windhoek"
  },
  {
    "value": "America/Adak",
    "label": "(GMT-10:00) America/Adak"
  },
  {
    "value": "America/Anchorage",
    "label": "(GMT-09:00) America/Anchorage"
  },
  {
    "value": "America/Anguilla",
    "label": "(GMT-04:00) America/Anguilla"
  },
  {
    "value": "America/Antigua",
    "label": "(GMT-04:00) America/Antigua"
  },
  {
    "value": "America/Araguaina",
    "label": "(GMT-03:00) America/Araguaina"
  },
  {
    "value": "America/Argentina/Buenos_Aires",
    "label": "(GMT-03:00) America/Argentina/Buenos_Aires"
  },
  {
    "value": "America/Argentina/Catamarca",
    "label": "(GMT-03:00) America/Argentina/Catamarca"
  },
  {
    "value": "America/Argentina/ComodRivadavia",
    "label": "(GMT-03:00) America/Argentina/ComodRivadavia"
  },
  {
    "value": "America/Argentina/Cordoba",
    "label": "(GMT-03:00) America/Argentina/Cordoba"
  },
  {
    "value": "America/Argentina/Jujuy",
    "label": "(GMT-03:00) America/Argentina/Jujuy"
  },
  {
    "value": "America/Argentina/La_Rioja",
    "label": "(GMT-03:00) America/Argentina/La_Rioja"
  },
  {
    "value": "America/Argentina/Mendoza",
    "label": "(GMT-03:00) America/Argentina/Mendoza"
  },
  {
    "value": "America/Argentina/Rio_Gallegos",
    "label": "(GMT-03:00) America/Argentina/Rio_Gallegos"
  },
  {
    "value": "America/Argentina/Salta",
    "label": "(GMT-03:00) America/Argentina/Salta"
  },
  {
    "value": "America/Argentina/San_Juan",
    "label": "(GMT-03:00) America/Argentina/San_Juan"
  },
  {
    "value": "America/Argentina/San_Luis",
    "label": "(GMT-03:00) America/Argentina/San_Luis"
  },
  {
    "value": "America/Argentina/Tucuman",
    "label": "(GMT-03:00) America/Argentina/Tucuman"
  },
  {
    "value": "America/Argentina/Ushuaia",
    "label": "(GMT-03:00) America/Argentina/Ushuaia"
  },
  {
    "value": "America/Aruba",
    "label": "(GMT-04:00) America/Aruba"
  },
  {
    "value": "America/Asuncion",
    "label": "(GMT-03:00) America/Asuncion"
  },
  {
    "value": "America/Atikokan",
    "label": "(GMT-05:00) America/Atikokan"
  },
  {
    "value": "America/Atka",
    "label": "(GMT-10:00) America/Atka"
  },
  {
    "value": "America/Bahia",
    "label": "(GMT-03:00) America/Bahia"
  },
  {
    "value": "America/Bahia_Banderas",
    "label": "(GMT-06:00) America/Bahia_Banderas"
  },
  {
    "value": "America/Barbados",
    "label": "(GMT-04:00) America/Barbados"
  },
  {
    "value": "America/Belem",
    "label": "(GMT-03:00) America/Belem"
  },
  {
    "value": "America/Belize",
    "label": "(GMT-06:00) America/Belize"
  },
  {
    "value": "America/Blanc-Sablon",
    "label": "(GMT-04:00) America/Blanc-Sablon"
  },
  {
    "value": "America/Boa_Vista",
    "label": "(GMT-04:00) America/Boa_Vista"
  },
  {
    "value": "America/Bogota",
    "label": "(GMT-05:00) America/Bogota"
  },
  {
    "value": "America/Boise",
    "label": "(GMT-07:00) America/Boise"
  },
  {
    "value": "America/Buenos_Aires",
    "label": "(GMT-03:00) America/Buenos_Aires"
  },
  {
    "value": "America/Cambridge_Bay",
    "label": "(GMT-07:00) America/Cambridge_Bay"
  },
  {
    "value": "America/Campo_Grande",
    "label": "(GMT-04:00) America/Campo_Grande"
  },
  {
    "value": "America/Cancun",
    "label": "(GMT-05:00) America/Cancun"
  },
  {
    "value": "America/Caracas",
    "label": "(GMT-04:00) America/Caracas"
  },
  {
    "value": "America/Catamarca",
    "label": "(GMT-03:00) America/Catamarca"
  },
  {
    "value": "America/Cayenne",
    "label": "(GMT-03:00) America/Cayenne"
  },
  {
    "value": "America/Cayman",
    "label": "(GMT-05:00) America/Cayman"
  },
  {
    "value": "America/Chicago",
    "label": "(GMT-06:00) America/Chicago"
  },
  {
    "value": "America/Chihuahua",
    "label": "(GMT-06:00) America/Chihuahua"
  },
  {
    "value": "America/Ciudad_Juarez",
    "label": "(GMT-07:00) America/Ciudad_Juarez"
  },
  {
    "value": "America/Coral_Harbour",
    "label": "(GMT-05:00) America/Coral_Harbour"
  },
  {
    "value": "America/Cordoba",
    "label": "(GMT-03:00) America/Cordoba"
  },
  {
    "value": "America/Costa_Rica",
    "label": "(GMT-06:00) America/Costa_Rica"
  },
  {
    "value": "America/Creston",
    "label": "(GMT-07:00) America/Creston"
  },
  {
    "value": "America/Cuiaba",
    "label": "(GMT-04:00) America/Cuiaba"
  },
  {
    "value": "America/Curacao",
    "label": "(GMT-04:00) America/Curacao"
  },
  {
    "value": "America/Danmarkshavn",
    "label": "(GMT+00:00) America/Danmarkshavn"
  },
  {
    "value": "America/Dawson",
    "label": "(GMT-07:00) America/Dawson"
  },
  {
    "value": "America/Dawson_Creek",
    "label": "(GMT-07:00) America/Dawson_Creek"
  },
  {
    "value": "America/Denver",
    "label": "(GMT-07:00) America/Denver"
  },
  {
    "value": "America/Detroit",
    "label": "(GMT-05:00) America/Detroit"
  },
  {
    "value": "America/Dominica",
    "label": "(GMT-04:00) America/Dominica"
  },
  {
    "value": "America/Edmonton",
    "label": "(GMT-07:00) America/Edmonton"
  },
  {
    "value": "America/Eirunepe",
    "label": "(GMT-05:00) America/Eirunepe"
  },
  {
    "value": "America/El_Salvador",
    "label": "(GMT-06:00) America/El_Salvador"
  },
  {
    "value": "America/Ensenada",
    "label": "(GMT-08:00) America/Ensenada"
  },
  {
    "value": "America/Fort_Nelson",
    "label": "(GMT-07:00) America/Fort_Nelson"
  },
  {
    "value": "America/Fort_Wayne",
    "label": "(GMT-05:00) America/Fort_Wayne"
  },
  {
    "value": "America/Fortaleza",
    "label": "(GMT-03:00) America/Fortaleza"
  },
  {
    "value": "America/Glace_Bay",
    "label": "(GMT-04:00) America/Glace_Bay"
  },
  {
    "value": "America/Godthab",
    "label": "(GMT-02:00) America/Godthab"
  },
  {
    "value": "America/Goose_Bay",
    "label": "(GMT-04:00) America/Goose_Bay"
  },
  {
    "value": "America/Grand_Turk",
    "label": "(GMT-05:00) America/Grand_Turk"
  },
  {
    "value": "America/Grenada",
    "label": "(GMT-04:00) America/Grenada"
  },
  {
    "value": "America/Guadeloupe",
    "label": "(GMT-04:00) America/Guadeloupe"
  },
  {
    "value": "America/Guatemala",
    "label": "(GMT-06:00) America/Guatemala"
  },
  {
    "value": "America/Guayaquil",
    "label": "(GMT-05:00) America/Guayaquil"
  },
  {
    "value": "America/Guyana",
    "label": "(GMT-04:00) America/Guyana"
  },
  {
    "value": "America/Halifax",
    "label": "(GMT-04:00) America/Halifax"
  },
  {
    "value": "America/Havana",
    "label": "(GMT-05:00) America/Havana"
  },
  {
    "value": "America/Hermosillo",
    "label": "(GMT-07:00) America/Hermosillo"
  },
  {
    "value": "America/Indiana/Indianapolis",
    "label": "(GMT-05:00) America/Indiana/Indianapolis"
  },
  {
    "value": "America/Indiana/Knox",
    "label": "(GMT-06:00) America/Indiana/Knox"
  },
  {
    "value": "America/Indiana/Marengo",
    "label": "(GMT-05:00) America/Indiana/Marengo"
  },
  {
    "value": "America/Indiana/Petersburg",
    "label": "(GMT-05:00) America/Indiana/Petersburg"
  },
  {
    "value": "America/Indiana/Tell_City",
    "label": "(GMT-06:00) America/Indiana/Tell_City"
  },
  {
    "value": "America/Indiana/Vevay",
    "label": "(GMT-05:00) America/Indiana/Vevay"
  },
  {
    "value": "America/Indiana/Vincennes",
    "label": "(GMT-05:00) America/Indiana/Vincennes"
  },
  {
    "value": "America/Indiana/Winamac",
    "label": "(GMT-05:00) America/Indiana/Winamac"
  },
  {
    "value": "America/Indianapolis",
    "label": "(GMT-05:00) America/Indianapolis"
  },
  {
    "value": "America/Inuvik",
    "label": "(GMT-07:00) America/Inuvik"
  },
  {
    "value": "America/Iqaluit",
    "label": "(GMT-05:00) America/Iqaluit"
  },
  {
    "value": "America/Jamaica",
    "label": "(GMT-05:00) America/Jamaica"
  },
  {
    "value": "America/Jujuy",
    "label": "(GMT-03:00) America/Jujuy"
  },
  {
    "value": "America/Juneau",
    "label": "(GMT-09:00) America/Juneau"
  },
  {
    "value": "America/Kentucky/Louisville",
    "label": "(GMT-05:00) America/Kentucky/Louisville"
  },
  {
    "value": "America/Kentucky/Monticello",
    "label": "(GMT-05:00) America/Kentucky/Monticello"
  },
  {
    "value": "America/Knox_IN",
    "label": "(GMT-06:00) America/Knox_IN"
  },
  {
    "value": "America/Kralendijk",
    "label": "(GMT-04:00) America/Kralendijk"
  },
  {
    "value": "America/La_Paz",
    "label": "(GMT-04:00) America/La_Paz"
  },
  {
    "value": "America/Lima",
    "label": "(GMT-05:00) America/Lima"
  },
  {
    "value": "America/Los_Angeles",
    "label": "(GMT-08:00) America/Los_Angeles"
  },
  {
    "value": "America/Louisville",
    "label": "(GMT-05:00) America/Louisville"
  },
  {
    "value": "America/Lower_Princes",
    "label": "(GMT-04:00) America/Lower_Princes"
  },
  {
    "value": "America/Maceio",
    "label": "(GMT-03:00) America/Maceio"
  },
  {
    "value": "America/Managua",
    "label": "(GMT-06:00) America/Managua"
  },
  {
    "value": "America/Manaus",
    "label": "(GMT-04:00) America/Manaus"
  },
  {
    "value": "America/Marigot",
    "label": "(GMT-04:00) America/Marigot"
  },
  {
    "value": "America/Martinique",
    "label": "(GMT-04:00) America/Martinique"
  },
  {
    "value": "America/Matamoros",
    "label": "(GMT-06:00) America/Matamoros"
  },
  {
    "value": "America/Mazatlan",
    "label": "(GMT-07:00) America/Mazatlan"
  },
  {
    "value": "America/Mendoza",
    "label": "(GMT-03:00) America/Mendoza"
  },
  {
    "value": "America/Menominee",
    "label": "(GMT-06:00) America/Menominee"
  },
  {
    "value": "America/Merida",
    "label": "(GMT-06:00) America/Merida"
  },
  {
    "value": "America/Metlakatla",
    "label": "(GMT-09:00) America/Metlakatla"
  },
  {
    "value": "America/Mexico_City",
    "label": "(GMT-06:00) America/Mexico_City"
  },
  {
    "value": "America/Miquelon",
    "label": "(GMT-03:00) America/Miquelon"
  },
  {
    "value": "America/Moncton",
    "label": "(GMT-04:00) America/Moncton"
  },
  {
    "value": "America/Monterrey",
    "label": "(GMT-06:00) America/Monterrey"
  },
  {
    "value": "America/Montevideo",
    "label": "(GMT-03:00) America/Montevideo"
  },
  {
    "value": "America/Montreal",
    "label": "(GMT-05:00) America/Montreal"
  },
  {
    "value": "America/Montserrat",
    "label": "(GMT-04:00) America/Montserrat"
  },
  {
    "value": "America/Nassau",
    "label": "(GMT-05:00) America/Nassau"
  },
  {
    "value": "America/New_York",
    "label": "(GMT-05:00) America/New_York"
  },
  {
    "value": "America/Nipigon",
    "label": "(GMT-05:00) America/Nipigon"
  },
  {
    "value": "America/Nome",
    "label": "(GMT-09:00) America/Nome"
  },
  {
    "value": "America/Noronha",
    "label": "(GMT-02:00) America/Noronha"
  },
  {
    "value": "America/North_Dakota/Beulah",
    "label": "(GMT-06:00) America/North_Dakota/Beulah"
  },
  {
    "value": "America/North_Dakota/Center",
    "label": "(GMT-06:00) America/North_Dakota/Center"
  },
  {
    "value": "America/North_Dakota/New_Salem",
    "label": "(GMT-06:00) America/North_Dakota/New_Salem"
  },
  {
    "value": "America/Nuuk",
    "label": "(GMT-02:00) America/Nuuk"
  },
  {
    "value": "America/Ojinaga",
    "label": "(GMT-06:00) America/Ojinaga"
  },
  {
    "value": "America/Panama",
    "label": "(GMT-05:00) America/Panama"
  },
  {
    "value": "America/Pangnirtung",
    "label": "(GMT-05:00) America/Pangnirtung"
  },
  {
    "value": "America/Paramaribo",
    "label": "(GMT-03:00) America/Paramaribo"
  },
  {
    "value": "America/Phoenix",
    "label": "(GMT-07:00) America/Phoenix"
  },
  {
    "value": "America/Port-au-Prince",
    "label": "(GMT-05:00) America/Port-au-Prince"
  },
  {
    "value": "America/Port_of_Spain",
    "label": "(GMT-04:00) America/Port_of_Spain"
  },
  {
    "value": "America/Porto_Acre",
    "label": "(GMT-05:00) America/Porto_Acre"
  },
  {
    "value": "America/Porto_Velho",
    "label": "(GMT-04:00) America/Porto_Velho"
  },
  {
    "value": "America/Puerto_Rico",
    "label": "(GMT-04:00) America/Puerto_Rico"
  },
  {
    "value": "America/Punta_Arenas",
    "label": "(GMT-03:00) America/Punta_Arenas"
  },
  {
    "value": "America/Rainy_River",
    "label": "(GMT-06:00) America/Rainy_River"
  },
  {
    "value": "America/Rankin_Inlet",
    "label": "(GMT-06:00) America/Rankin_Inlet"
  },
  {
    "value": "America/Recife",
    "label": "(GMT-03:00) America/Recife"
  },
  {
    "value": "America/Regina",
    "label": "(GMT-06:00) America/Regina"
  },
  {
    "value": "America/Resolute",
    "label": "(GMT-06:00) America/Resolute"
  },
  {
    "value": "America/Rio_Branco",
    "label": "(GMT-05:00) America/Rio_Branco"
  },
  {
    "value": "America/Rosario",
    "label": "(GMT-03:00) America/Rosario"
  },
  {
    "value": "America/Santa_Isabel",
    "label": "(GMT-08:00) America/Santa_Isabel"
  },
  {
    "value": "America/Santarem",
    "label": "(GMT-03:00) America/Santarem"
  },
  {
    "value": "America/Santiago",
    "label": "(GMT-03:00) America/Santiago"
  },
  {
    "value": "America/Santo_Domingo",
    "label": "(GMT-04:00) America/Santo_Domingo"
  },
  {
    "value": "America/Sao_Paulo",
    "label": "(GMT-03:00) America/Sao_Paulo"
  },
  {
    "value": "America/Scoresbysund",
    "label": "(GMT-01:00) America/Scoresbysund"
  },
  {
    "value": "America/Shiprock",
    "label": "(GMT-07:00) America/Shiprock"
  },
  {
    "value": "America/Sitka",
    "label": "(GMT-09:00) America/Sitka"
  },
  {
    "value": "America/St_Barthelemy",
    "label": "(GMT-04:00) America/St_Barthelemy"
  },
  {
    "value": "America/St_Johns",
    "label": "(GMT-03:30) America/St_Johns"
  },
  {
    "value": "America/St_Kitts",
    "label": "(GMT-04:00) America/St_Kitts"
  },
  {
    "value": "America/St_Lucia",
    "label": "(GMT-04:00) America/St_Lucia"
  },
  {
    "value": "America/St_Thomas",
    "label": "(GMT-04:00) America/St_Thomas"
  },
  {
    "value": "America/St_Vincent",
    "label": "(GMT-04:00) America/St_Vincent"
  },
  {
    "value": "America/Swift_Current",
    "label": "(GMT-06:00) America/Swift_Current"
  },
  {
    "value": "America/Tegucigalpa",
    "label": "(GMT-06:00) America/Tegucigalpa"
  },
  {
    "value": "America/Thule",
    "label": "(GMT-04:00) America/Thule"
  },
  {
    "value": "America/Thunder_Bay",
    "label": "(GMT-05:00) America/Thunder_Bay"
  },
  {
    "value": "America/Tijuana",
    "label": "(GMT-08:00) America/Tijuana"
  },
  {
    "value": "America/Toronto",
    "label": "(GMT-05:00) America/Toronto"
  },
  {
    "value": "America/Tortola",
    "label": "(GMT-04:00) America/Tortola"
  },
  {
    "value": "America/Vancouver",
    "label": "(GMT-08:00) America/Vancouver"
  },
  {
    "value": "America/Virgin",
    "label": "(GMT-04:00) America/Virgin"
  },
  {
    "value": "America/Whitehorse",
    "label": "(GMT-07:00) America/Whitehorse"
  },
  {
    "value": "America/Winnipeg",
    "label": "(GMT-06:00) America/Winnipeg"
  },
  {
    "value": "America/Yakutat",
    "label": "(GMT-09:00) America/Yakutat"
  },
  {
    "value": "America/Yellowknife",
    "label": "(GMT-07:00) America/Yellowknife"
  },
  {
    "value": "Antarctica/Casey",
    "label": "(GMT+08:00) Antarctica/Casey"
  },
  {
    "value": "Antarctica/Davis",
    "label": "(GMT+07:00) Antarctica/Davis"
  },
  {
    "value": "Antarctica/DumontDUrville",
    "label": "(GMT+10:00) Antarctica/DumontDUrville"
  },
  {
    "value": "Antarctica/Macquarie",
    "label": "(GMT+11:00) Antarctica/Macquarie"
  },
  {
    "value": "Antarctica/Mawson",
    "label": "(GMT+05:00) Antarctica/Mawson"
  },
  {
    "value": "Antarctica/McMurdo",
    "label": "(GMT+13:00) Antarctica/McMurdo"
  },
  {
    "value": "Antarctica/Palmer",
    "label": "(GMT-03:00) Antarctica/Palmer"
  },
  {
    "value": "Antarctica/Rothera",
    "label": "(GMT-03:00) Antarctica/Rothera"
  },
  {
    "value": "Antarctica/South_Pole",
    "label": "(GMT+13:00) Antarctica/South_Pole"
  },
  {
    "value": "Antarctica/Syowa",
    "label": "(GMT+03:00) Antarctica/Syowa"
  },
  {
    "value": "Antarctica/Troll",
    "label": "(GMT+00:00) Antarctica/Troll"
  },
  {
    "value": "Antarctica/Vostok",
    "label": "(GMT+05:00) Antarctica/Vostok"
  },
  {
    "value": "Arctic/Longyearbyen",
    "label": "(GMT+01:00) Arctic/Longyearbyen"
  },
  {
    "value": "Asia/Aden",
    "label": "(GMT+03:00) Asia/Aden"
  },
  {
    "value": "Asia/Almaty",
    "label": "(GMT+06:00) Asia/Almaty"
  },
  {
    "value": "Asia/Amman",
    "label": "(GMT+03:00) Asia/Amman"
  },
  {
    "value": "Asia/Anadyr",
    "label": "(GMT+12:00) Asia/Anadyr"
  },
  {
    "value": "Asia/Aqtau",
    "label": "(GMT+05:00) Asia/Aqtau"
  },
  {
    "value": "Asia/Aqtobe",
    "label": "(GMT+05:00) Asia/Aqtobe"
  },
  {
    "value": "Asia/Ashgabat",
    "label": "(GMT+05:00) Asia/Ashgabat"
  },
  {
    "value": "Asia/Ashkhabad",
    "label": "(GMT+05:00) Asia/Ashkhabad"
  },
  {
    "value": "Asia/Atyrau",
    "label": "(GMT+05:00) Asia/Atyrau"
  },
  {
    "value": "Asia/Baghdad",
    "label": "(GMT+03:00) Asia/Baghdad"
  },
  {
    "value": "Asia/Bahrain",
    "label": "(GMT+03:00) Asia/Bahrain"
  },
  {
    "value": "Asia/Baku",
    "label": "(GMT+04:00) Asia/Baku"
  },
  {
    "value": "Asia/Bangkok",
    "label": "(GMT+07:00) Asia/Bangkok"
  },
  {
    "value": "Asia/Barnaul",
    "label": "(GMT+07:00) Asia/Barnaul"
  },
  {
    "value": "Asia/Beirut",
    "label": "(GMT+02:00) Asia/Beirut"
  },
  {
    "value": "Asia/Bishkek",
    "label": "(GMT+06:00) Asia/Bishkek"
  },
  {
    "value": "Asia/Brunei",
    "label": "(GMT+08:00) Asia/Brunei"
  },
  {
    "value": "Asia/Calcutta",
    "label": "(GMT+05:30) Asia/Calcutta"
  },
  {
    "value": "Asia/Chita",
    "label": "(GMT+09:00) Asia/Chita"
  },
  {
    "value": "Asia/Choibalsan",
    "label": "(GMT+08:00) Asia/Choibalsan"
  },
  {
    "value": "Asia/Chongqing",
    "label": "(GMT+08:00) Asia/Chongqing"
  },
  {
    "value": "Asia/Chungking",
    "label": "(GMT+08:00) Asia/Chungking"
  },
  {
    "value": "Asia/Colombo",
    "label": "(GMT+05:30) Asia/Colombo"
  },
  {
    "value": "Asia/Dacca",
    "label": "(GMT+06:00) Asia/Dacca"
  },
  {
    "value": "Asia/Damascus",
    "label": "(GMT+03:00) Asia/Damascus"
  },
  {
    "value": "Asia/Dhaka",
    "label": "(GMT+06:00) Asia/Dhaka"
  },
  {
    "value": "Asia/Dili",
    "label": "(GMT+09:00) Asia/Dili"
  },
  {
    "value": "Asia/Dubai",
    "label": "(GMT+04:00) Asia/Dubai"
  },
  {
    "value": "Asia/Dushanbe",
    "label": "(GMT+05:00) Asia/Dushanbe"
  },
  {
    "value": "Asia/Famagusta",
    "label": "(GMT+02:00) Asia/Famagusta"
  },
  {
    "value": "Asia/Gaza",
    "label": "(GMT+02:00) Asia/Gaza"
  },
  {
    "value": "Asia/Harbin",
    "label": "(GMT+08:00) Asia/Harbin"
  },
  {
    "value": "Asia/Hebron",
    "label": "(GMT+02:00) Asia/Hebron"
  },
  {
    "value": "Asia/Ho_Chi_Minh",
    "label": "(GMT+07:00) Asia/Ho_Chi_Minh"
  },
  {
    "value": "Asia/Hong_Kong",
    "label": "(GMT+08:00) Asia/Hong_Kong"
  },
  {
    "value": "Asia/Hovd",
    "label": "(GMT+07:00) Asia/Hovd"
  },
  {
    "value": "Asia/Irkutsk",
    "label": "(GMT+08:00) Asia/Irkutsk"
  },
  {
    "value": "Asia/Istanbul",
    "label": "(GMT+03:00) Asia/Istanbul"
  },
  {
    "value": "Asia/Jakarta",
    "label": "(GMT+07:00) Asia/Jakarta"
  },
  {
    "value": "Asia/Jayapura",
    "label": "(GMT+09:00) Asia/Jayapura"
  },
  {
    "value": "Asia/Jerusalem",
    "label": "(GMT+02:00) Asia/Jerusalem"
  },
  {
    "value": "Asia/Kabul",
    "label": "(GMT+04:30) Asia/Kabul"
  },
  {
    "value": "Asia/Kamchatka",
    "label": "(GMT+12:00) Asia/Kamchatka"
  },
  {
    "value": "Asia/Karachi",
    "label": "(GMT+05:00) Asia/Karachi"
  },
  {
    "value": "Asia/Kashgar",
    "label": "(GMT+06:00) Asia/Kashgar"
  },
  {
    "value": "Asia/Kathmandu",
    "label": "(GMT+05:45) Asia/Kathmandu"
  },
  {
    "value": "Asia/Katmandu",
    "label": "(GMT+05:45) Asia/Katmandu"
  },
  {
    "value": "Asia/Khandyga",
    "label": "(GMT+09:00) Asia/Khandyga"
  },
  {
    "value": "Asia/Kolkata",
    "label": "(GMT+05:30) Asia/Kolkata"
  },
  {
    "value": "Asia/Krasnoyarsk",
    "label": "(GMT+07:00) Asia/Krasnoyarsk"
  },
  {
    "value": "Asia/Kuala_Lumpur",
    "label": "(GMT+08:00) Asia/Kuala_Lumpur"
  },
  {
    "value": "Asia/Kuching",
    "label": "(GMT+08:00) Asia/Kuching"
  },
  {
    "value": "Asia/Kuwait",
    "label": "(GMT+03:00) Asia/Kuwait"
  },
  {
    "value": "Asia/Macao",
    "label": "(GMT+08:00) Asia/Macao"
  },
  {
    "value": "Asia/Macau",
    "label": "(GMT+08:00) Asia/Macau"
  },
  {
    "value": "Asia/Magadan",
    "label": "(GMT+11:00) Asia/Magadan"
  },
  {
    "value": "Asia/Makassar",
    "label": "(GMT+08:00) Asia/Makassar"
  },
  {
    "value": "Asia/Manila",
    "label": "(GMT+08:00) Asia/Manila"
  },
  {
    "value": "Asia/Muscat",
    "label": "(GMT+04:00) Asia/Muscat"
  },
  {
    "value": "Asia/Nicosia",
    "label": "(GMT+02:00) Asia/Nicosia"
  },
  {
    "value": "Asia/Novokuznetsk",
    "label": "(GMT+07:00) Asia/Novokuznetsk"
  },
  {
    "value": "Asia/Novosibirsk",
    "label": "(GMT+07:00) Asia/Novosibirsk"
  },
  {
    "value": "Asia/Omsk",
    "label": "(GMT+06:00) Asia/Omsk"
  },
  {
    "value": "Asia/Oral",
    "label": "(GMT+05:00) Asia/Oral"
  },
  {
    "value": "Asia/Phnom_Penh",
    "label": "(GMT+07:00) Asia/Phnom_Penh"
  },
  {
    "value": "Asia/Pontianak",
    "label": "(GMT+07:00) Asia/Pontianak"
  },
  {
    "value": "Asia/Pyongyang",
    "label": "(GMT+09:00) Asia/Pyongyang"
  },
  {
    "value": "Asia/Qatar",
    "label": "(GMT+03:00) Asia/Qatar"
  },
  {
    "value": "Asia/Qostanay",
    "label": "(GMT+06:00) Asia/Qostanay"
  },
  {
    "value": "Asia/Qyzylorda",
    "label": "(GMT+05:00) Asia/Qyzylorda"
  },
  {
    "value": "Asia/Rangoon",
    "label": "(GMT+06:30) Asia/Rangoon"
  },
  {
    "value": "Asia/Riyadh",
    "label": "(GMT+03:00) Asia/Riyadh"
  },
  {
    "value": "Asia/Saigon",
    "label": "(GMT+07:00) Asia/Saigon"
  },
  {
    "value": "Asia/Sakhalin",
    "label": "(GMT+11:00) Asia/Sakhalin"
  },
  {
    "value": "Asia/Samarkand",
    "label": "(GMT+05:00) Asia/Samarkand"
  },
  {
    "value": "Asia/Seoul",
    "label": "(GMT+09:00) Asia/Seoul"
  },
  {
    "value": "Asia/Shanghai",
    "label": "(GMT+08:00) Asia/Shanghai"
  },
  {
    "value": "Asia/Singapore",
    "label": "(GMT+08:00) Asia/Singapore"
  },
  {
    "value": "Asia/Srednekolymsk",
    "label": "(GMT+11:00) Asia/Srednekolymsk"
  },
  {
    "value": "Asia/Taipei",
    "label": "(GMT+08:00) Asia/Taipei"
  },
  {
    "value": "Asia/Tashkent",
    "label": "(GMT+05:00) Asia/Tashkent"
  },
  {
    "value": "Asia/Tbilisi",
    "label": "(GMT+04:00) Asia/Tbilisi"
  },
  {
    "value": "Asia/Tehran",
    "label": "(GMT+03:30) Asia/Tehran"
  },
  {
    "value": "Asia/Tel_Aviv",
    "label": "(GMT+02:00) Asia/Tel_Aviv"
  },
  {
    "value": "Asia/Thimbu",
    "label": "(GMT+06:00) Asia/Thimbu"
  },
  {
    "value": "Asia/Thimphu",
    "label": "(GMT+06:00) Asia/Thimphu"
  },
  {
    "value": "Asia/Tokyo",
    "label": "(GMT+09:00) Asia/Tokyo"
  },
  {
    "value": "Asia/Tomsk",
    "label": "(GMT+07:00) Asia/Tomsk"
  },
  {
    "value": "Asia/Ujung_Pandang",
    "label": "(GMT+08:00) Asia/Ujung_Pandang"
  },
  {
    "value": "Asia/Ulaanbaatar",
    "label": "(GMT+08:00) Asia/Ulaanbaatar"
  },
  {
    "value": "Asia/Ulan_Bator",
    "label": "(GMT+08:00) Asia/Ulan_Bator"
  },
  {
    "value": "Asia/Urumqi",
    "label": "(GMT+06:00) Asia/Urumqi"
  },
  {
    "value": "Asia/Ust-Nera",
    "label": "(GMT+10:00) Asia/Ust-Nera"
  },
  {
    "value": "Asia/Vientiane",
    "label": "(GMT+07:00) Asia/Vientiane"
  },
  {
    "value": "Asia/Vladivostok",
    "label": "(GMT+10:00) Asia/Vladivostok"
  },
  {
    "value": "Asia/Yakutsk",
    "label": "(GMT+09:00) Asia/Yakutsk"
  },
  {
    "value": "Asia/Yangon",
    "label": "(GMT+06:30) Asia/Yangon"
  },
  {
    "value": "Asia/Yekaterinburg",
    "label": "(GMT+05:00) Asia/Yekaterinburg"
  },
  {
    "value": "Asia/Yerevan",
    "label": "(GMT+04:00) Asia/Yerevan"
  },
  {
    "value": "Atlantic/Azores",
    "label": "(GMT-01:00) Atlantic/Azores"
  },
  {
    "value": "Atlantic/Bermuda",
    "label": "(GMT-04:00) Atlantic/Bermuda"
  },
  {
    "value": "Atlantic/Canary",
    "label": "(GMT+00:00) Atlantic/Canary"
  },
  {
    "value": "Atlantic/Cape_Verde",
    "label": "(GMT-01:00) Atlantic/Cape_Verde"
  },
  {
    "value": "Atlantic/Faeroe",
    "label": "(GMT+00:00) Atlantic/Faeroe"
  },
  {
    "value": "Atlantic/Faroe",
    "label": "(GMT+00:00) Atlantic/Faroe"
  },
  {
    "value": "Atlantic/Jan_Mayen",
    "label": "(GMT+01:00) Atlantic/Jan_Mayen"
  },
  {
    "value": "Atlantic/Madeira",
    "label": "(GMT+00:00) Atlantic/Madeira"
  },
  {
    "value": "Atlantic/Reykjavik",
    "label": "(GMT+00:00) Atlantic/Reykjavik"
  },
  {
    "value": "Atlantic/South_Georgia",
    "label": "(GMT-02:00) Atlantic/South_Georgia"
  },
  {
    "value": "Atlantic/St_Helena",
    "label": "(GMT+00:00) Atlantic/St_Helena"
  },
  {
    "value": "Atlantic/Stanley",
    "label": "(GMT-03:00) Atlantic/Stanley"
  },
  {
    "value": "Australia/ACT",
    "label": "(GMT+11:00) Australia/ACT"
  },
  {
    "value": "Australia/Adelaide",
    "label": "(GMT+10:30) Australia/Adelaide"
  },
  {
    "value": "Australia/Brisbane",
    "label": "(GMT+10:00) Australia/Brisbane"
  },
  {
    "value": "Australia/Broken_Hill",
    "label": "(GMT+10:30) Australia/Broken_Hill"
  },
  {
    "value": "Australia/Canberra",
    "label": "(GMT+11:00) Australia/Canberra"
  },
  {
    "value": "Australia/Currie",
    "label": "(GMT+11:00) Australia/Currie"
  },
  {
    "value": "Australia/Darwin",
    "label": "(GMT+09:30) Australia/Darwin"
  },
  {
    "value": "Australia/Eucla",
    "label": "(GMT+08:45) Australia/Eucla"
  },
  {
    "value": "Australia/Hobart",
    "label": "(GMT+11:00) Australia/Hobart"
  },
  {
    "value": "Australia/LHI",
    "label": "(GMT+11:00) Australia/LHI"
  },
  {
    "value": "Australia/Lindeman",
    "label": "(GMT+10:00) Australia/Lindeman"
  },
  {
    "value": "Australia/Lord_Howe",
    "label": "(GMT+11:00) Australia/Lord_Howe"
  },
  {
    "value": "Australia/Melbourne",
    "label": "(GMT+11:00) Australia/Melbourne"
  },
  {
    "value": "Australia/NSW",
    "label": "(GMT+11:00) Australia/NSW"
  },
  {
    "value": "Australia/North",
    "label": "(GMT+09:30) Australia/North"
  },
  {
    "value": "Australia/Perth",
    "label": "(GMT+08:00) Australia/Perth"
  },
  {
    "value": "Australia/Queensland",
    "label": "(GMT+10:00) Australia/Queensland"
  },
  {
    "value": "Australia/South",
    "label": "(GMT+10:30) Australia/South"
  },
  {
    "value": "Australia/Sydney",
    "label": "(GMT+11:00) Australia/Sydney"
  },
  {
    "value": "Australia/Tasmania",
    "label": "(GMT+11:00) Australia/Tasmania"
  },
  {
    "value": "Australia/Victoria",
    "label": "(GMT+11:00) Australia/Victoria"
  },
  {
    "value": "Australia/West",
    "label": "(GMT+08:00) Australia/West"
  },
  {
    "value": "Australia/Yancowinna",
    "label": "(GMT+10:30) Australia/Yancowinna"
  },
  {
    "value": "Brazil/Acre",
    "label": "(GMT-05:00) Brazil/Acre"
  },
  {
    "value": "Brazil/DeNoronha",
    "label": "(GMT-02:00) Brazil/DeNoronha"
  },
  {
    "value": "Brazil/East",
    "label": "(GMT-03:00) Brazil/East"
  },
  {
    "value": "Brazil/West",
    "label": "(GMT-04:00) Brazil/West"
  },
  {
    "value": "CET",
    "label": "(GMT+01:00) CET"
  },
  {
    "value": "CST6CDT",
    "label": "(GMT-06:00) CST6CDT"
  },
  {
    "value": "Canada/Atlantic",
    "label": "(GMT-04:00) Canada/Atlantic"
  },
  {
    "value": "Canada/Central",
    "label": "(GMT-06:00) Canada/Central"
  },
  {
    "value": "Canada/Eastern",
    "label": "(GMT-05:00) Canada/Eastern"
  },
  {
    "value": "Canada/Mountain",
    "label": "(GMT-07:00) Canada/Mountain"
  },
  {
    "value": "Canada/Newfoundland",
    "label": "(GMT-03:30) Canada/Newfoundland"
  },
  {
    "value": "Canada/Pacific",
    "label": "(GMT-08:00) Canada/Pacific"
  },
  {
    "value": "Canada/Saskatchewan",
    "label": "(GMT-06:00) Canada/Saskatchewan"
  },
  {
    "value": "Canada/Yukon",
    "label": "(GMT-07:00) Canada/Yukon"
  },
  {
    "value": "Chile/Continental",
    "label": "(GMT-03:00) Chile/Continental"
  },
  {
    "value": "Chile/EasterIsland",
    "label": "(GMT-05:00) Chile/EasterIsland"
  },
  {
    "value": "Cuba",
    "label": "(GMT-05:00) Cuba"
  },
  {
    "value": "EET",
    "label": "(GMT+02:00) EET"
  },
  {
    "value": "EST",
    "label": "(GMT-05:00) EST"
  },
  {
    "value": "EST5EDT",
    "label": "(GMT-05:00) EST5EDT"
  },
  {
    "value": "Egypt",
    "label": "(GMT+02:00) Egypt"
  },
  {
    "value": "Eire",
    "label": "(GMT+00:00) Eire"
  },
  {
    "value": "Etc/GMT",
    "label": "(GMT+00:00) Etc/GMT"
  },
  {
    "value": "Etc/GMT+0",
    "label": "(GMT+00:00) Etc/GMT+0"
  },
  {
    "value": "Etc/GMT+1",
    "label": "(GMT-01:00) Etc/GMT+1"
  },
  {
    "value": "Etc/GMT+10",
    "label": "(GMT-10:00) Etc/GMT+10"
  },
  {
    "value": "Etc/GMT+11",
    "label": "(GMT-11:00) Etc/GMT+11"
  },
  {
    "value": "Etc/GMT+12",
    "label": "(GMT-12:00) Etc/GMT+12"
  },
  {
    "value": "Etc/GMT+2",
    "label": "(GMT-02:00) Etc/GMT+2"
  },
  {
    "value": "Etc/GMT+3",
    "label": "(GMT-03:00) Etc/GMT+3"
  },
  {
    "value": "Etc/GMT+4",
    "label": "(GMT-04:00) Etc/GMT+4"
  },
  {
    "value": "Etc/GMT+5",
    "label": "(GMT-05:00) Etc/GMT+5"
  },
  {
    "value": "Etc/GMT+6",
    "label": "(GMT-06:00) Etc/GMT+6"
  },
  {
    "value": "Etc/GMT+7",
    "label": "(GMT-07:00) Etc/GMT+7"
  },
  {
    "value": "Etc/GMT+8",
    "label": "(GMT-08:00) Etc/GMT+8"
  },
  {
    "value": "Etc/GMT+9",
    "label": "(GMT-09:00) Etc/GMT+9"
  },
  {
    "value": "Etc/GMT-0",
    "label": "(GMT+00:00) Etc/GMT-0"
  },
  {
    "value": "Etc/GMT-1",
    "label": "(GMT+01:00) Etc/GMT-1"
  },
  {
    "value": "Etc/GMT-10",
    "label": "(GMT+10:00) Etc/GMT-10"
  },
  {
    "value": "Etc/GMT-11",
    "label": "(GMT+11:00) Etc/GMT-11"
  },
  {
    "value": "Etc/GMT-12",
    "label": "(GMT+12:00) Etc/GMT-12"
  },
  {
    "value": "Etc/GMT-13",
    "label": "(GMT+13:00) Etc/GMT-13"
  },
  {
    "value": "Etc/GMT-14",
    "label": "(GMT+14:00) Etc/GMT-14"
  },
  {
    "value": "Etc/GMT-2",
    "label": "(GMT+02:00) Etc/GMT-2"
  },
  {
    "value": "Etc/GMT-3",
    "label": "(GMT+03:00) Etc/GMT-3"
  },
  {
    "value": "Etc/GMT-4",
    "label": "(GMT+04:00) Etc/GMT-4"
  },
  {
    "value": "Etc/GMT-5",
    "label": "(GMT+05:00) Etc/GMT-5"
  },
  {
    "value": "Etc/GMT-6",
    "label": "(GMT+06:00) Etc/GMT-6"
  },
  {
    "value": "Etc/GMT-7",
    "label": "(GMT+07:00) Etc/GMT-7"
  },
  {
    "value": "Etc/GMT-8",
    "label": "(GMT+08:00) Etc/GMT-8"
  },
  {
    "value": "Etc/GMT-9",
    "label": "(GMT+09:00) Etc/GMT-9"
  },
  {
    "value": "Etc/GMT0",
    "label": "(GMT+00:00) Etc/GMT0"
  },
  {
    "value": "Etc/Greenwich",
    "label": "(GMT+00:00) Etc/Greenwich"
  },
  {
    "value": "Etc/UCT",
    "label": "(GMT+00:00) Etc/UCT"
  },
  {
    "value": "Etc/UTC",
    "label": "(GMT+00:00) Etc/UTC"
  },
  {
    "value": "Etc/Universal",
    "label": "(GMT+00:00) Etc/Universal"
  },
  {
    "value": "Etc/Zulu",
    "label": "(GMT+00:00) Etc/Zulu"
  },
  {
    "value": "Europe/Amsterdam",
    "label": "(GMT+01:00) Europe/Amsterdam"
  },
  {
    "value": "Europe/Andorra",
    "label": "(GMT+01:00) Europe/Andorra"
  },
  {
    "value": "Europe/Astrakhan",
    "label": "(GMT+04:00) Europe/Astrakhan"
  },
  {
    "value": "Europe/Athens",
    "label": "(GMT+02:00) Europe/Athens"
  },
  {
    "value": "Europe/Belfast",
    "label": "(GMT+00:00) Europe/Belfast"
  },
  {
    "value": "Europe/Belgrade",
    "label": "(GMT+01:00) Europe/Belgrade"
  },
  {
    "value": "Europe/Berlin",
    "label": "(GMT+01:00) Europe/Berlin"
  },
  {
    "value": "Europe/Bratislava",
    "label": "(GMT+01:00) Europe/Bratislava"
  },
  {
    "value": "Europe/Brussels",
    "label": "(GMT+01:00) Europe/Brussels"
  },
  {
    "value": "Europe/Bucharest",
    "label": "(GMT+02:00) Europe/Bucharest"
  },
  {
    "value": "Europe/Budapest",
    "label": "(GMT+01:00) Europe/Budapest"
  },
  {
    "value": "Europe/Busingen",
    "label": "(GMT+01:00) Europe/Busingen"
  },
  {
    "value": "Europe/Chisinau",
    "label": "(GMT+02:00) Europe/Chisinau"
  },
  {
    "value": "Europe/Copenhagen",
    "label": "(GMT+01:00) Europe/Copenhagen"
  },
  {
    "value": "Europe/Dublin",
    "label": "(GMT+00:00) Europe/Dublin"
  },
  {
    "value": "Europe/Gibraltar",
    "label": "(GMT+01:00) Europe/Gibraltar"
  },
  {
    "value": "Europe/Guernsey",
    "label": "(GMT+00:00) Europe/Guernsey"
  },
  {
    "value": "Europe/Helsinki",
    "label": "(GMT+02:00) Europe/Helsinki"
  },
  {
    "value": "Europe/Isle_of_Man",
    "label": "(GMT+00:00) Europe/Isle_of_Man"
  },
  {
    "value": "Europe/Istanbul",
    "label": "(GMT+03:00) Europe/Istanbul"
  },
  {
    "value": "Europe/Jersey",
    "label": "(GMT+00:00) Europe/Jersey"
  },
  {
    "value": "Europe/Kaliningrad",
    "label": "(GMT+02:00) Europe/Kaliningrad"
  },
  {
    "value": "Europe/Kiev",
    "label": "(GMT+02:00) Europe/Kiev"
  },
  {
    "value": "Europe/Kirov",
    "label": "(GMT+03:00) Europe/Kirov"
  },
  {
    "value": "Europe/Kyiv",
    "label": "(GMT+02:00) Europe/Kyiv"
  },
  {
    "value": "Europe/Lisbon",
    "label": "(GMT+00:00) Europe/Lisbon"
  },
  {
    "value": "Europe/Ljubljana",
    "label": "(GMT+01:00) Europe/Ljubljana"
  },
  {
    "value": "Europe/London",
    "label": "(GMT+00:00) Europe/London"
  },
  {
    "value": "Europe/Luxembourg",
    "label": "(GMT+01:00) Europe/Luxembourg"
  },
  {
    "value": "Europe/Madrid",
    "label": "(GMT+01:00) Europe/Madrid"
  },
  {
    "value": "Europe/Malta",
    "label": "(GMT+01:00) Europe/Malta"
  },
  {
    "value": "Europe/Mariehamn",
    "label": "(GMT+02:00) Europe/Mariehamn"
  },
  {
    "value": "Europe/Minsk",
    "label": "(GMT+03:00) Europe/Minsk"
  },
  {
    "value": "Europe/Monaco",
    "label": "(GMT+01:00) Europe/Monaco"
  },
  {
    "value": "Europe/Moscow",
    "label": "(GMT+03:00) Europe/Moscow"
  },
  {
    "value": "Europe/Nicosia",
    "label": "(GMT+02:00) Europe/Nicosia"
  },
  {
    "value": "Europe/Oslo",
    "label": "(GMT+01:00) Europe/Oslo"
  },
  {
    "value": "Europe/Paris",
    "label": "(GMT+01:00) Europe/Paris"
  },
  {
    "value": "Europe/Podgorica",
    "label": "(GMT+01:00) Europe/Podgorica"
  },
  {
    "value": "Europe/Prague",
    "label": "(GMT+01:00) Europe/Prague"
  },
  {
    "value": "Europe/Riga",
    "label": "(GMT+02:00) Europe/Riga"
  },
  {
    "value": "Europe/Rome",
    "label": "(GMT+01:00) Europe/Rome"
  },
  {
    "value": "Europe/Samara",
    "label": "(GMT+04:00) Europe/Samara"
  },
  {
    "value": "Europe/San_Marino",
    "label": "(GMT+01:00) Europe/San_Marino"
  },
  {
    "value": "Europe/Sarajevo",
    "label": "(GMT+01:00) Europe/Sarajevo"
  },
  {
    "value": "Europe/Saratov",
    "label": "(GMT+04:00) Europe/Saratov"
  },
  {
    "value": "Europe/Simferopol",
    "label": "(GMT+03:00) Europe/Simferopol"
  },
  {
    "value": "Europe/Skopje",
    "label": "(GMT+01:00) Europe/Skopje"
  },
  {
    "value": "Europe/Sofia",
    "label": "(GMT+02:00) Europe/Sofia"
  },
  {
    "value": "Europe/Stockholm",
    "label": "(GMT+01:00) Europe/Stockholm"
  },
  {
    "value": "Europe/Tallinn",
    "label": "(GMT+02:00) Europe/Tallinn"
  },
  {
    "value": "Europe/Tirane",
    "label": "(GMT+01:00) Europe/Tirane"
  },
  {
    "value": "Europe/Tiraspol",
    "label": "(GMT+02:00) Europe/Tiraspol"
  },
  {
    "value": "Europe/Ulyanovsk",
    "label": "(GMT+04:00) Europe/Ulyanovsk"
  },
  {
    "value": "Europe/Uzhgorod",
    "label": "(GMT+02:00) Europe/Uzhgorod"
  },
  {
    "value": "Europe/Vaduz",
    "label": "(GMT+01:00) Europe/Vaduz"
  },
  {
    "value": "Europe/Vatican",
    "label": "(GMT+01:00) Europe/Vatican"
  },
  {
    "value": "Europe/Vienna",
    "label": "(GMT+01:00) Europe/Vienna"
  },
  {
    "value": "Europe/Vilnius",
    "label": "(GMT+02:00) Europe/Vilnius"
  },
  {
    "value": "Europe/Volgograd",
    "label": "(GMT+03:00) Europe/Volgograd"
  },
  {
    "value": "Europe/Warsaw",
    "label": "(GMT+01:00) Europe/Warsaw"
  },
  {
    "value": "Europe/Zagreb",
    "label": "(GMT+01:00) Europe/Zagreb"
  },
  {
    "value": "Europe/Zaporozhye",
    "label": "(GMT+02:00) Europe/Zaporozhye"
  },
  {
    "value": "Europe/Zurich",
    "label": "(GMT+01:00) Europe/Zurich"
  },
  {
    "value": "GB",
    "label": "(GMT+00:00) GB"
  },
  {
    "value": "GB-Eire",
    "label": "(GMT+00:00) GB-Eire"
  },
  {
    "value": "GMT",
    "label": "(GMT+00:00) GMT"
  },
  {
    "value": "GMT+0",
    "label": "(GMT+00:00) GMT+0"
  },
  {
    "value": "GMT-0",
    "label": "(GMT+00:00) GMT-0"
  },
  {
    "value": "GMT0",
    "label": "(GMT+00:00) GMT0"
  },
  {
    "value": "Greenwich",
    "label": "(GMT+00:00) Greenwich"
  },
  {
    "value": "HST",
    "label": "(GMT-10:00) HST"
  },
  {
    "value": "Hongkong",
    "label": "(GMT+08:00) Hongkong"
  },
  {
    "value": "Iceland",
    "label": "(GMT+00:00) Iceland"
  },
  {
    "value": "Indian/Antananarivo",
    "label": "(GMT+03:00) Indian/Antananarivo"
  },
  {
    "value": "Indian/Chagos",
    "label": "(GMT+06:00) Indian/Chagos"
  },
  {
    "value": "Indian/Christmas",
    "label": "(GMT+07:00) Indian/Christmas"
  },
  {
    "value": "Indian/Cocos",
    "label": "(GMT+06:30) Indian/Cocos"
  },
  {
    "value": "Indian/Comoro",
    "label": "(GMT+03:00) Indian/Comoro"
  },
  {
    "value": "Indian/Kerguelen",
    "label": "(GMT+05:00) Indian/Kerguelen"
  },
  {
    "value": "Indian/Mahe",
    "label": "(GMT+04:00) Indian/Mahe"
  },
  {
    "value": "Indian/Maldives",
    "label": "(GMT+05:00) Indian/Maldives"
  },
  {
    "value": "Indian/Mauritius",
    "label": "(GMT+04:00) Indian/Mauritius"
  },
  {
    "value": "Indian/Mayotte",
    "label": "(GMT+03:00) Indian/Mayotte"
  },
  {
    "value": "Indian/Reunion",
    "label": "(GMT+04:00) Indian/Reunion"
  },
  {
    "value": "Iran",
    "label": "(GMT+03:30) Iran"
  },
  {
    "value": "Israel",
    "label": "(GMT+02:00) Israel"
  },
  {
    "value": "Jamaica",
    "label": "(GMT-05:00) Jamaica"
  },
  {
    "value": "Japan",
    "label": "(GMT+09:00) Japan"
  },
  {
    "value": "Kwajalein",
    "label": "(GMT+12:00) Kwajalein"
  },
  {
    "value": "Libya",
    "label": "(GMT+02:00) Libya"
  },
  {
    "value": "MET",
    "label": "(GMT+01:00) MET"
  },
  {
    "value": "MST",
    "label": "(GMT-07:00) MST"
  },
  {
    "value": "MST7MDT",
    "label": "(GMT-07:00) MST7MDT"
  },
  {
    "value": "Mexico/BajaNorte",
    "label": "(GMT-08:00) Mexico/BajaNorte"
  },
  {
    "value": "Mexico/BajaSur",
    "label": "(GMT-07:00) Mexico/BajaSur"
  },
  {
    "value": "Mexico/General",
    "label": "(GMT-06:00) Mexico/General"
  },
  {
    "value": "NZ",
    "label": "(GMT+13:00) NZ"
  },
  {
    "value": "NZ-CHAT",
    "label": "(GMT+13:45) NZ-CHAT"
  },
  {
    "value": "Navajo",
    "label": "(GMT-07:00) Navajo"
  },
  {
    "value": "PRC",
    "label": "(GMT+08:00) PRC"
  },
  {
    "value": "PST8PDT",
    "label": "(GMT-08:00) PST8PDT"
  },
  {
    "value": "Pacific/Apia",
    "label": "(GMT+13:00) Pacific/Apia"
  },
  {
    "value": "Pacific/Auckland",
    "label": "(GMT+13:00) Pacific/Auckland"
  },
  {
    "value": "Pacific/Bougainville",
    "label": "(GMT+11:00) Pacific/Bougainville"
  },
  {
    "value": "Pacific/Chatham",
    "label": "(GMT+13:45) Pacific/Chatham"
  },
  {
    "value": "Pacific/Chuuk",
    "label": "(GMT+10:00) Pacific/Chuuk"
  },
  {
    "value": "Pacific/Easter",
    "label": "(GMT-05:00) Pacific/Easter"
  },
  {
    "value": "Pacific/Efate",
    "label": "(GMT+11:00) Pacific/Efate"
  },
  {
    "value": "Pacific/Enderbury",
    "label": "(GMT+13:00) Pacific/Enderbury"
  },
  {
    "value": "Pacific/Fakaofo",
    "label": "(GMT+13:00) Pacific/Fakaofo"
  },
  {
    "value": "Pacific/Fiji",
    "label": "(GMT+12:00) Pacific/Fiji"
  },
  {
    "value": "Pacific/Funafuti",
    "label": "(GMT+12:00) Pacific/Funafuti"
  },
  {
    "value": "Pacific/Galapagos",
    "label": "(GMT-06:00) Pacific/Galapagos"
  },
  {
    "value": "Pacific/Gambier",
    "label": "(GMT-09:00) Pacific/Gambier"
  },
  {
    "value": "Pacific/Guadalcanal",
    "label": "(GMT+11:00) Pacific/Guadalcanal"
  },
  {
    "value": "Pacific/Guam",
    "label": "(GMT+10:00) Pacific/Guam"
  },
  {
    "value": "Pacific/Honolulu",
    "label": "(GMT-10:00) Pacific/Honolulu"
  },
  {
    "value": "Pacific/Johnston",
    "label": "(GMT-10:00) Pacific/Johnston"
  },
  {
    "value": "Pacific/Kanton",
    "label": "(GMT+13:00) Pacific/Kanton"
  },
  {
    "value": "Pacific/Kiritimati",
    "label": "(GMT+14:00) Pacific/Kiritimati"
  },
  {
    "value": "Pacific/Kosrae",
    "label": "(GMT+11:00) Pacific/Kosrae"
  },
  {
    "value": "Pacific/Kwajalein",
    "label": "(GMT+12:00) Pacific/Kwajalein"
  },
  {
    "value": "Pacific/Majuro",
    "label": "(GMT+12:00) Pacific/Majuro"
  },
  {
    "value": "Pacific/Marquesas",
    "label": "(GMT-09:30) Pacific/Marquesas"
  },
  {
    "value": "Pacific/Midway",
    "label": "(GMT-11:00) Pacific/Midway"
  },
  {
    "value": "Pacific/Nauru",
    "label": "(GMT+12:00) Pacific/Nauru"
  },
  {
    "value": "Pacific/Niue",
    "label": "(GMT-11:00) Pacific/Niue"
  },
  {
    "value": "Pacific/Norfolk",
    "label": "(GMT+12:00) Pacific/Norfolk"
  },
  {
    "value": "Pacific/Noumea",
    "label": "(GMT+11:00) Pacific/Noumea"
  },
  {
    "value": "Pacific/Pago_Pago",
    "label": "(GMT-11:00) Pacific/Pago_Pago"
  },
  {
    "value": "Pacific/Palau",
    "label": "(GMT+09:00) Pacific/Palau"
  },
  {
    "value": "Pacific/Pitcairn",
    "label": "(GMT-08:00) Pacific/Pitcairn"
  },
  {
    "value": "Pacific/Pohnpei",
    "label": "(GMT+11:00) Pacific/Pohnpei"
  },
  {
    "value": "Pacific/Ponape",
    "label": "(GMT+11:00) Pacific/Ponape"
  },
  {
    "value": "Pacific/Port_Moresby",
    "label": "(GMT+10:00) Pacific/Port_Moresby"
  },
  {
    "value": "Pacific/Rarotonga",
    "label": "(GMT-10:00) Pacific/Rarotonga"
  },
  {
    "value": "Pacific/Saipan",
    "label": "(GMT+10:00) Pacific/Saipan"
  },
  {
    "value": "Pacific/Samoa",
    "label": "(GMT-11:00) Pacific/Samoa"
  },
  {
    "value": "Pacific/Tahiti",
    "label": "(GMT-10:00) Pacific/Tahiti"
  },
  {
    "value": "Pacific/Tarawa",
    "label": "(GMT+12:00) Pacific/Tarawa"
  },
  {
    "value": "Pacific/Tongatapu",
    "label": "(GMT+13:00) Pacific/Tongatapu"
  },
  {
    "value": "Pacific/Truk",
    "label": "(GMT+10:00) Pacific/Truk"
  },
  {
    "value": "Pacific/Wake",
    "label": "(GMT+12:00) Pacific/Wake"
  },
  {
    "value": "Pacific/Wallis",
    "label": "(GMT+12:00) Pacific/Wallis"
  },
  {
    "value": "Pacific/Yap",
    "label": "(GMT+10:00) Pacific/Yap"
  },
  {
    "value": "Poland",
    "label": "(GMT+01:00) Poland"
  },
  {
    "value": "Portugal",
    "label": "(GMT+00:00) Portugal"
  },
  {
    "value": "ROC",
    "label": "(GMT+08:00) ROC"
  },
  {
    "value": "ROK",
    "label": "(GMT+09:00) ROK"
  },
  {
    "value": "Singapore",
    "label": "(GMT+08:00) Singapore"
  },
  {
    "value": "Turkey",
    "label": "(GMT+03:00) Turkey"
  },
  {
    "value": "UCT",
    "label": "(GMT+00:00) UCT"
  },
  {
    "value": "US/Alaska",
    "label": "(GMT-09:00) US/Alaska"
  },
  {
    "value": "US/Aleutian",
    "label": "(GMT-10:00) US/Aleutian"
  },
  {
    "value": "US/Arizona",
    "label": "(GMT-07:00) US/Arizona"
  },
  {
    "value": "US/Central",
    "label": "(GMT-06:00) US/Central"
  },
  {
    "value": "US/East-Indiana",
    "label": "(GMT-05:00) US/East-Indiana"
  },
  {
    "value": "US/Eastern",
    "label": "(GMT-05:00) US/Eastern"
  },
  {
    "value": "US/Hawaii",
    "label": "(GMT-10:00) US/Hawaii"
  },
  {
    "value": "US/Indiana-Starke",
    "label": "(GMT-06:00) US/Indiana-Starke"
  },
  {
    "value": "US/Michigan",
    "label": "(GMT-05:00) US/Michigan"
  },
  {
    "value": "US/Mountain",
    "label": "(GMT-07:00) US/Mountain"
  },
  {
    "value": "US/Pacific",
    "label": "(GMT-08:00) US/Pacific"
  },
  {
    "value": "US/Samoa",
    "label": "(GMT-11:00) US/Samoa"
  },
  {
    "value": "UTC",
    "label": "(GMT+00:00) UTC"
  },
  {
    "value": "Universal",
    "label": "(GMT+00:00) Universal"
  },
  {
    "value": "W-SU",
    "label": "(GMT+03:00) W-SU"
  },
  {
    "value": "WET",
    "label": "(GMT+00:00) WET"
  },
  {
    "value": "Zulu",
    "label": "(GMT+00:00) Zulu"
  }
]

export const ImageTypes = [".jpg", "jpeg", ".png"];
export const resumeTypes = [
  "application/doc",
  "application/pdf",
  "application/msword",
  "application/ms-doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

/**
 * Job applications company status
 *
 */
export const companyApplicationStatuses = {
  QUALIFIED: {
    id: 1,
    title: "qualified",
  },
  CONTACTED: {
    id: 2,
    title: "contacted",
  },
  SCREENING: {
    id: 3,
    title: "screening",
  },
  INTERVIEWED: {
    id: 4,
    title: "interviewed",
  },
  HIRED: {
    id: 5,
    title: "hired",
  },
  REJECTED: {
    id: 6,
    title: "rejected",
  },
};

/**
 * Job application statuses
 *
 */
export const jobApplicationStatuses = {
  APPLIED: {
    id: 1,
    title: "applied",
  },
  SAVED: {
    id: 2,
    title: "saved",
  },
};

export const connectionRequestStatus = [
  {
    status_id: 1,
    status_text: "pending",
  },
  {
    status_id: 2,
    status_text: "accepted",
  },
  {
    status_id: 3,
    status_text: "rejected",
  },
  {
    status_id: 4,
    status_text: "cancelled_24_hours",
  },
];

/**
 * Candidate Favorite or not boolean
 *
 */
export const candidateFavoriteStatus = {
  FAVORITE: {
    id: 1,
    title: "favorite",
  },
  UNFAVORITE: {
    id: 2,
    title: "unfavorite",
  },
};

export const CandidateColors = {
  DEEP_PINK: "#FF0990",
  LIGHT_PINK: "#FFCCCC",
  HIRED_COLOR: "#C2EE99",
  REJECTED_COLOR: "#1966DD",
};

export const FileTypes: { [key: string]: string } = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
};

export const ImageMimeTypes = ["image/png", "image/jpeg", "image/jpg"];
export const VideoMimeTypes = ["video/mp4", "video/quicktime"];

/**
 * Job salary term
 *
 */
export const jobSalaryTerms = [
  {
    value: "annual",
    label: "Annual",
  },
  {
    value: "per_hour",
    label: "Per Hour",
  },
];

/**
 * Job salary term
 *
 */
export const REMOTE_CHOICE = [
  {
    value: "1",
    label: "On-Site",
  },
  {
    value: "2",
    label: "Remote",
  },
  {
    value: "3",
    label: "Hybrid",
  },
];

export const SUPPORT_VISA = [
  {
    value: "0",
    label: "No",
  },
  {
    value: "1",
    label: "Yes",
  },
];

/**
 * All the MB conversion are made in bytes
 *
 */
export const bytesMb = {
  mb_1: 1048576,
  mb_2: 2097152,
  mb_3: 3145728,
  mb_4: 4194304,
  mb_5: 5242880,
  mb_7: 7340032,
  mb_10: 10485760,
  mb_30: 31457280,
  mb_50: 52428800,
};

/**
 * How many video can be uploaded by candidate
 *
 */
export const FINAL_VIDEO_DURATION_LIMIT = 70; // seconds
export const VIDEO_STUDIO_UPLOAD_LIMIT = 3;
export const VIDEO_STUDIO_FILE_ADD_LIMIT = 4;
export const MIN_VIDEO_DURATION_SEC = 5;
export const VIDEO_THUMB_BAR_WIDTH = 900;
export const ALLOW_VIDEO_MIN_HEIGHT = 576;
export const ALLOW_VIDEO_MIN_WIDTH = 720;

/**
 * Color code for event types
 *
 */
export const EVENT_COLOR_CODE = "#f44336";

/**
 * visibibility state for profile visibility
 *
 */
export const candidateVisibilityStatus = {
  public: 1,
  private: 2,
};

/**
 * Is Company Flag
 *
 */
export const COMPANY_FLAG = {
  YES: 2,
  NO: 1,
};

/**
 * Refer a Friend status
 *
 */
export const referFriendStatus = [
  {
    id: 1,
    title: "Invited",
  },
  {
    id: 2,
    title: "Signed up",
  },
  {
    id: 10,
    title: "Completed",
  },
];

/**
 * Notification type
 *
 */
export const notificationTypes = [
  {
    label: "Application Process",
    value: 1,
  },
  {
    label: "Connection",
    value: 2,
  },
  {
    label: "Candidate Job Applied",
    value: 3,
  },
];
