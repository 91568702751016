import {
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio as MuiRadio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Skeleton from "@material-ui/lab/Skeleton";
import EmojiPicker from "emoji-picker-react";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient, { Socket } from "socket.io-client";
import { ReactComponent as PrevArrow } from "../../assets/svg/angle-left-pink.svg";
import { ReactComponent as NextArrow } from "../../assets/svg/angle-right-pink.svg";
import {
  Button,
  FlashMessage,
  Input,
  Modal,
  Radio,
} from "../../components/common";
import AsyncSelect from "../../components/common/AsyncSelect";
import Image from "../../components/common/Image";
import usePrevious from "../../hooks/usePrevious";
import useSessionUser from "../../hooks/useSessionUser";
import { rootReducersState } from "../../reducers";
import {
  fetchUsersListRequest,
  resetUsersList,
} from "../../reducers/messages/usersList.reducer";
import appRoutes from "../../routes/app.routes";
import messagesServices from "../../services/messages.services";
import { IS_DEVELOPMENT, TIME_ZONE_LIST } from "../../utils/appConstants";
import {
  fetchAndClearNewMessageInfo,
  openUrlInNewTab,
} from "../../utils/helper";
import ConversationList from "./components/conversationList";
import Emoji from "./components/emoji";
import MessagesList from "./components/messagesList";
import AddImage from "./images/icons/add-icon.svg";
import CalenderIcon from "./images/icons/calender-icon.svg";
import { useParams } from "react-router-dom";
import { setMessageSendType } from "../../reducers/common/candidateProfilePersists.reducer";
import Avatar from "../../components/common/AvtarImage";
import { updateMessageCount } from "../../reducers/auth/session.reducer";
type Inputs = {
  message: string;
};
let tmpActiveRoomDetails = {};
let tmpSocket;
let searchDelay: any = null;

const Messages = () => {
  const sessionUser = useSessionUser();

  const chatMesRef = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLTextAreaElement | null>(null);
  const dispatch = useDispatch();
  const params = useParams<{ companySlug: string }>();
  const companySlug = params?.companySlug ? params.companySlug : null;

  const messagesReducer = useSelector(({ message }: any) => message);
  const usersList = _.get(messagesReducer, "usersList.list", []) || [];
  const usersListLoading = _.get(messagesReducer, "usersList.loading", null);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );

  const messageSendType = useSelector(
    ({ commonPersist }: rootReducersState) => commonPersist.messageSendType
  );

  const [anchorMenu, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorMenu);

  const [socket, setSocket] = useState<Socket>(null);
  const [messageText, setMessageText] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formObject, setFormObject] = useState<object>({});
  const [messagesList, setMessagesList] = useState<Array<any>>([]);
  const [activeRoomDetails, setActiveRoomDetails] = useState<any>({});
  const [totalMessagesCount, setTotalMessagesCount] = useState<number>(0);
  const [conversationList, setConversationList] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionsList, setOptionList] = useState<Array<number>>([
    15, 30, 60, 120,
  ]);
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [radio, setRadio] = useState(null);
  const [slots, setSlots] = useState<Array<object>>([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [info, setInfo] = useState<boolean>(false);
  // const [smallScreen, setsmallScreen] = useState<boolean>(true);
  const [showNewMessageModal, setShowNewMessageModal] =
    useState<boolean>(false);
  const [intervalData, setIntervalData] = useState<string>("Select Interval");
  const [loadMoreMessagesFlag, setLoadMoreMessagesFlag] =
    useState<boolean>(false);
  const basicInfoStore = useSelector(
    ({ company }: any) => company?.basicInfo || {}
  );
  const basicInfoErrors = _.get(basicInfoStore, "errors", {});
  const [bannerImageName, setBannerImageName] = useState("");
  const [togglePicker, setTogglePicker] = useState<boolean>(false);
  const [currentRoom, setCurrentRoom] = useState({});
  const [totalUnreadCount, setUnreadCount] = useState();
  const { setError } = useForm<Inputs>();
  const radioOptions = [
    { label: "Video Meeting", value: "video_meeting" },
    { label: "Phone Call", value: "phone_call" },
  ];
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    control,
    reset: resetForm,
  } = useForm({
    mode: "onBlur",
  });

  const prevMessageText = usePrevious(messageText);

  // close popup when clicked outside container
  const emojiRef = useRef<any>(null);
  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };
  const tmpCurrentUser = _.get(sessionReducer, "currentUser", {});
  const userId = _.get(tmpCurrentUser, "id", 0);

  useEffect(() => {
    document.addEventListener("click", hideEmojis, true);

    _.forEach(basicInfoErrors, (value, key: any) => {
      setError(key, { type: "manual", message: value });
    });
    // Socket IO Config Once;

    const socketInstance = socketIOClient(
      String(process.env.REACT_APP_API_END_POINT)
    );
    tmpSocket = socketInstance;
    setSocket(socketInstance); // set socket for other components

    socketInstance.on("connect", function () {
      // Fetch all conversation list
      setListLoading(true);
      socketInstance.emit("fetchConversationList", { userId, companySlug });
    });

    // Ask other member to join the room for 1-1 conversation
    socketInstance.on("inviteRoom", (data) => {
      socketInstance.emit("joinRoom", data);
    });

    // Fetch the list of all conversation once load the page
    socketInstance.on("fetchConversationList", async (data) => {
      setListLoading(false);
      const tmpList = _.get(data, "conversationList.data", []);
      setConversationList(tmpList);

      const chatData = fetchAndClearNewMessageInfo();
      if (_.get(chatData, "uuid", "")) {
        await _handleInitializeNewChat(chatData, tmpList);
      } else if (!activeRoomDetails?.uuid && tmpList?.length > 0) {
        const firstConversation = tmpList[0];
        // Initialize the chat with tmpSocket since socket instance will be null, also check _handleClickConversion method if require
        const chatRoom = firstConversation.chatRoom;
        setActiveRoomDetails(firstConversation);
        tmpActiveRoomDetails = firstConversation;

        // Start Room
        tmpSocket.emit("createRoom", chatRoom);

        // Fetch chat room messages + Reset the previous chat data only when change the room
        setCurrentPage(1);
        tmpSocket.emit("fetchChatMessages", {
          userId,
          chatRoom,
          isCompany: firstConversation.isCompany,
          page: 1,
        });

        // Focus on text input after conversation is selected
        focusOnTextInput();
      }
    });

    // Receive the message from another user
    socketInstance.on("emitMessage", (data) => {
      const messageData = _.get(data, "payload", {});

      if (typeof messageData === "object") {
        // Set last message in the conversation chat
        setConversationList((prevList) =>
          [...prevList].map((row) => {
            if (row.chatRoom === messageData.chatRoom) {
              return {
                ...row,
                lastMessage: messageData.message,
                lastMessageOn: messageData.timeStamp,
              };
            }
            return row;
          })
        );

        // Scroll to bottom if incoming message room and current message room is same
        if (
          messageData.chatRoom === _.get(tmpActiveRoomDetails, "chatRoom", "")
        ) {
          setMessagesList((prevMsg) => [...prevMsg, { ...messageData }]);
          scrollToDown();
        }
      }
    });
    // Fetch + Set current room messages with pagination
    socketInstance.on("fetchChatMessages", (data) => {
      const chatRoom = data.messagesList.roomId
      const candidateMessageCount = data.messagesList.count
      const totalUnread = data.messagesList.totalCount.count

      setUnreadCount(totalUnread)
      setCurrentRoom({
        chatRoom: chatRoom,
        unreadCount: candidateMessageCount
      })
      // Set total messages
      setTotalMessagesCount(_.get(data, "messagesList.data.count", 0));

      let tmpMessagesList = (
        _.get(data, "messagesList.data.rows", []) || []
      ).map((row) => {
        return {
          chatRoom: row.room_id,
          fromUser: row.user_id,
          message: row.message,
          timeStamp: moment(row.created_at).format(),
          messageFrom:
            row.user_id === _.get(tmpActiveRoomDetails, "senderId", 0)
              ? "me"
              : "",
        };
      });

      const page = _.get(data, "messagesList.pagination.page", 0);

      tmpMessagesList = _.reverse(tmpMessagesList);
      setMessagesList((prevMessages) => [...tmpMessagesList, ...prevMessages]);
      setLoadMoreMessagesFlag(false);

      // Scroll to down only when calling first time
      if (page === 1) {
        scrollToDown();
      }
    });

    return () => {
      document.removeEventListener("click", hideEmojis, true);
      if (socketInstance) {
        socketInstance.close();
      }
      if (typeof tmpSocket?.close === "function") {
        tmpSocket?.close();
      }
    };

    // TODO - fetched all pages at once, need to fix this

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const updatedConversationList = conversationList.map((e) => {
      const roomId = currentRoom?.chatRoom;
      const unreadCount = currentRoom?.unreadCount;
      if (e.chatRoom === roomId) {
        return {
          ...e,
          unread_count: unreadCount
        };
      } else {
        return e;
      }
    });
    const messageCountPayload = {
      candidateMessageCount: totalUnreadCount
    }
    dispatch(updateMessageCount(messageCountPayload));
    setConversationList(updatedConversationList);
    // eslint-disable-next-line
  }, [messagesList]);

  const scrollToDown = () => {
    const chatMessageList =
      chatMesRef?.current?.querySelector("#chat-message-list");
    if (chatMessageList) {
      chatMessageList.scrollTop = chatMessageList.scrollHeight + 2000;
    }
  };

  const onSendMessage = () => {
    const finalMessage = (messageText?.trim() || "").replace(
      /\r?\n|\r/g,
      "<br />"
    );

    if (!finalMessage) {
      setMessageText(prevMessageText || "");
      FlashMessage("Please type something", "error");
      return;
    }

    const lastMessageOn = moment().format();
    const payload = {
      chatRoom: activeRoomDetails.chatRoom,
      isCompany: activeRoomDetails?.isCompany || null,
      fromUser: activeRoomDetails?.senderId || 0,
      companyHandler: sessionUser.sessionUserId,
      message: finalMessage,
      timeStamp: lastMessageOn,
    };

    // Send the message
    socket.emit("emitMessage", payload);
    setMessagesList((prevMsg) => [
      ...prevMsg,
      { ...payload, messageFrom: "me" },
    ]);

    // Clear message
    setMessageText("");
    setTogglePicker(false);

    // Set last message in the left chat list
    setConversationList((prevList) =>
      [...prevList].map((row) => {
        if (row.chatRoom === activeRoomDetails.chatRoom) {
          return { ...row, lastMessage: finalMessage, lastMessageOn };
        }
        return row;
      })
    );

    setTimeout(() => scrollToDown(), 10);
  };

  const _handleClickConversion = (payload) => {

    if (activeRoomDetails.chatRoom !== payload.chatRoom) {
      setMessagesList([]); // reset messages list
      const chatRoom = payload.chatRoom;
      setActiveRoomDetails(payload);
      tmpActiveRoomDetails = payload;

      // Clear the message
      setMessageText("");

      // Start Room
      socket.emit("createRoom", chatRoom);

      // Fetch chat room messages + Reset the previous chat data only when change the room
      setCurrentPage(1);
      socket.emit("fetchChatMessages", {
        userId,
        chatRoom,
        isCompany: payload.isCompany,
        page: 1,
      });

      // Focus on text input after conversation is selected
      focusOnTextInput();
    }
  };

  const loadMoreMessages = () => {
    // Check if we have loaded all messages
    if (messagesList.length < totalMessagesCount && !loadMoreMessagesFlag) {
      setLoadMoreMessagesFlag(true);

      // Fetch chat room messages + Reset the previous chat data only when change the room
      const chatRoom = activeRoomDetails.chatRoom;
      const page = currentPage + 1;
      setCurrentPage(page);
      socket.emit("fetchChatMessages", {
        chatRoom,
        isCompany: activeRoomDetails?.isCompany || null,
        page,
      });
    }
  };

  const _handleCloseNewMessageModal = () => {
    setShowNewMessageModal(false);
    dispatch(resetUsersList());
  };
  const infoRef = useRef<any>(null);
  const _handleCloseCalenderModal = () => {
    setInfo(false);
    setShowCalendarModal(false);
    setLoading(false);
    handleDateChange(new Date());
    setSelectedSlot("");
    // eslint-disable-next-line no-implied-eval
    setIntervalData("Select Interval");
    setSlots([]);
    setSelectedIndex(null);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        // clicked out side
      } else {
        _handleCloseCalenderModal();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [infoRef]);

  const _handleSearchUsers = ({ currentTarget }) => {
    const { value } = currentTarget;

    clearTimeout(searchDelay);

    if ((value || "").trim()) {
      searchDelay = setTimeout(async () => {
        dispatch(
          fetchUsersListRequest({
            q: (value || "").trim(),
            companySlug: companySlug,
          })
        );
      }, 1000);
    } else {
      dispatch(resetUsersList());
    }
  };

  const _handleInitializeNewChat = async (data, tmpList = []) => {
    const result = await messagesServices.checkChatRoom({
      id: data.uuid,
      companySlug: companySlug,
    });

    if (_.get(result, "data.data")) {
      const roomData: any = _.get(result, "data.data", {});

      const chatRoom = roomData.chatRoom;
      const chatRoomSplit = roomData?.chatRoom?.split("_");
      const chatRoomData = {
        chatRoom: roomData.chatRoom,
        id: roomData.id,
        lastMessage: roomData.lastMessage,
        lastMessageOn: roomData.lastMessageOn,
        slug: data.slug,
        isCompany: data?.isCompany || null,
        senderId: Number(chatRoomSplit[0] || 0),
        receiverId: Number(chatRoomSplit[1] || 0),
        receiverName: `${data.first_name} ${data.last_name}`,
        receiverProfile: data.profile_image,
        redirect: data?.isCompany ? "company" : "candidate",
        roomUUID: roomData.roomUUID,
      };

      // Only for new conversion which are coming from other places
      const newConversationList = _.isEmpty(tmpList)
        ? conversationList
        : tmpList;

      // Check if conversion already exists
      const exists = _.find([...newConversationList], { chatRoom });

      if (exists) {
        setConversationList((prevList) =>
          [...newConversationList].map((row) => {
            if (row.chatRoom === chatRoom) {
              return chatRoomData;
            }
            return row;
          })
        );
      } else {
        setConversationList((prevList) => [
          { ...chatRoomData },
          ...newConversationList,
        ]);
      }

      // Initialize the chat room and close the window gently

      setMessagesList([]); // reset messages list
      setActiveRoomDetails(chatRoomData);
      tmpActiveRoomDetails = chatRoomData;

      if (tmpSocket) {
        // Start Room
        tmpSocket.emit("createRoom", chatRoom);

        // Fetch chat room messages + Reset the previous chat data only when change the room
        setCurrentPage(1);
        _handleCloseNewMessageModal();
        tmpSocket.emit("fetchChatMessages", {
          chatRoom,
          isCompany: data?.isCompany || null,
          page: 1,
        });
      } else {
        FlashMessage("Something went wrong while initialing message", "error");
      }
    } else {
      FlashMessage("Something went wrong while initialing message", "error");
    }
  };

  const _handleNavigateToProfile = () => {
    const slug = _.get(activeRoomDetails, "slug", "");
    let path = appRoutes.candidatePublicProfile.generateFullPath(slug);
    if (activeRoomDetails.redirect === "company") {
      path = appRoutes.CompanyPublicView.generatePath(slug);
    }

    openUrlInNewTab(path);
  };

  const _handleSubmit = async () => {
    if (selectedSlot) {
      setLoading(true);
      const formData = {
        date: moment(selectedDate).format("YYYY-MM-DD"),
        slot: selectedSlot,
        interval: intervalData,
        invitation_receiver_id: activeRoomDetails.receiverId,
      };
      await messagesServices
        .findInvitation(formData)
        .then((result) => {
          if (result.flag) {
            setFormObject(formData);
            FlashMessage(result.message);
            setInfo(true);
          } else {
            FlashMessage(result.message, "error");
          }
          setLoading(false);
          setIntervalData("Select Interval");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          FlashMessage(error?.message, "error");
        });
    } else {
      FlashMessage("Please select slot", "error");
    }
  };
  const calDate = (int: string) => {
    let slotObject = {
      slotInterval: parseInt(int),
      openTime: `${moment(new Date()).minutes() > 30
        ? moment(new Date()).add(1, "h").hour()
        : moment(new Date()).hour()
        }:${moment(new Date()).minutes() > 30 ? "00" : "30"}`,
      closeTime: `${moment(new Date()).add(1, "h").hour()}:${moment(
        new Date()
      ).minutes()}`,
    };
    let startTime = moment(slotObject.openTime, "HH:mm");
    let endTime = moment(slotObject.closeTime, "HH:mm").add(
      23 - new Date().getHours(),
      "hour"
    );
    let allTimes = [];
    while (startTime < endTime) {
      // @ts-ignore
      allTimes.push(startTime.format("HH:mm"));
      startTime.add(slotObject.slotInterval, "minutes");
    }
    return allTimes;
  };

  const handleChange = async (val: any) => {
    setIntervalData(val.target.value);
    setSelectedIndex(null);
    let target = await calDate(val.target.value);
    const slot = [];
    for (let i = 0; i < target.length - 1;) {
      if (target[i + 1] === "00:00") break;
      // @ts-ignore
      slot.push({
        value1: target[i],
        value2: target[i + 1] === undefined ? "00:00" : target[i + 1],
      });
      i = i + 1;
    }
    setSlots(slot);
  };

  const slotEx = (hour: string) => {
    if (parseInt(hour.split(":")[0]) > 12) return "PM";
    else return "AM";
  };

  const slotNumber = (hour: string) => {
    if (parseInt(hour.split(":")[0]) < 12) return hour;
    else return parseInt(hour.split(":")[0]) - 12 + ":" + hour.split(":")[1];
  };
  const onSelect = (val, index) => {
    setSelectedIndex(index);
    setSelectedSlot(val.value1 + "-" + val.value2);
  };

  const disInterval = (interval: number) => {
    if (interval > 50) {
      return interval / 60 + " Hour";
    } else {
      return interval + " Minutes";
    }
  };

  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = TIME_ZONE_LIST;
        resolve(locationData);
      }, 1000);
    });

  const setBanner = async (e: any) => {
    if (!_.isEmpty(e.target.files)) {
      const fileName = e.target.files[0].name;
      setBannerImageName(fileName);
    }
  };

  const _handleRadio = (radio) => {
    if (radio.target.value) setRadio(radio.target.value);
  };

  const cancelSubmission = () => {
    _handleCloseCalenderModal();
    resetForm({});
  };
  const onSubmit1 = async (formData) => {
    formData.image = bannerImageName;
    formData.location_id = formData.location_id.value;
    const payload = {
      ...formObject,
      ...formData,
    };
    await messagesServices
      .addInvitation(payload)
      .then((result) => {
        if (result.flag) {
          FlashMessage(result.message);
          _handleCloseCalenderModal();
          resetForm({});
        } else {
          FlashMessage(result.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
        FlashMessage(error?.message, "error");
      });
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const focusOnTextInput = () => {
    setTimeout(() => {
      messageInputRef?.current?.focus();
    }, 500);
  };

  return (
    <>
      <div className="max-container">
        <div
          id="chat-container"
          className="chat-container w-100 max-100 message-page-wrapper"
        >
          <div className="chat-container-top">
            <div id="search-container" className="search-container">
              {/* <input type="text" placeholder="Search" /> */}
              <div className="message-actions">
                <Button
                  className="new"
                  onClick={() => setShowNewMessageModal(true)}
                >
                  <Image src={AddImage} alt="Add Icon" />
                  <span>New</span>
                </Button>
              </div>
              {/* <Button

              className="manage"
            >
              <CreateIcon />  &nbsp; <span>Manage</span>
            </Button> */}
              <div className="search-bar">
                {/* <Image src={SearchImage} alt="Search Img" /> */}
                {/* <Input
                  name="search"
                  className="search-input"
                  type="text"
                  placeholder="Search Chats"
                /> */}
              </div>
            </div>
            {/* <div className="message-profile-top">

            </div> */}
          </div>
          <div className="chat-container-left" ref={infoRef}>
            {/* <div className="message-filter">
               <ul>
                <li>Individuals</li>
                <li>Employers</li>
                <li>Community</li>
              </ul>
            </div> */}
            <ConversationList
              conversationList={conversationList}
              activeRoomDetails={activeRoomDetails}
              loading={listLoading}
              onClickConversion={(payload) => _handleClickConversion(payload)}
            />
            <div id="new-message-container" className="new-message-container">
              {" "}
              <span />{" "}
            </div>
          </div>
          <div
            className="chat-container-right chat-calander-right"
            ref={chatMesRef}
          >
            {!_.isEmpty(activeRoomDetails) ? (
              <>
                {/* Seconde user to whom I am chatting */}
                <div id="chat-title" className="chat-title">
                  <div className="left">
                    <Avatar
                      src={_.get(activeRoomDetails, "receiverProfile", "")}
                      size="xsm"
                      type={(activeRoomDetails?.isCompany) ? "company" : "candidate"}
                      onClick={() => _handleNavigateToProfile()}
                    />
                    <div
                      className="chat-title-info cursor-pointer"
                      onClick={() => _handleNavigateToProfile()}
                    >
                      <p>{_.get(activeRoomDetails, "receiverName", "")}</p>
                      <span>{activeRoomDetails?.receiverJobTitle || ""}</span>
                    </div>
                  </div>
                  <div className="chat-right-wrapper">
                    {IS_DEVELOPMENT && (!activeRoomDetails?.isCompany) && (
                      <img
                        src={CalenderIcon}
                        width="22px"
                        height="22px"
                        onClick={() => {
                          if (showCalendarModal) {
                            _handleCloseCalenderModal();
                          } else {
                            setShowCalendarModal(true);
                          }
                        }}
                        className="span-link"
                        alt="calendar-icon"
                      />
                    )}
                    <Button
                      color="secondary"
                      onClick={() => _handleNavigateToProfile()}
                      className="text-uppercase"
                    >
                      Profile
                    </Button>
                  </div>
                  {/* <img src={trashLogo} alt="Delete Conversation" /> */}
                </div>
                {showCalendarModal ? (
                  <div className="calenderModal">
                    {!info ? (
                      <div className="calendar-wrapper">
                        <span
                          className="span-link float-right close-btn btn-secondary"
                          onClick={() => _handleCloseCalenderModal()}
                        >
                          <CloseOutlined />
                        </span>
                        <Grid container>
                          <Grid item xs={6}>
                            <div className="date-content">
                              <div className="calendar-date-wrapper">
                                <Calendar
                                  onChange={handleDateChange}
                                  value={selectedDate}
                                  next2Label={false}
                                  prev2Label={false}
                                  prevLabel={
                                    <span className="custom-prev-label">
                                      <PrevArrow />
                                    </span>
                                  }
                                  nextLabel={
                                    <span className="custom-prev-label">
                                      <NextArrow />
                                    </span>
                                  }
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="interval-wrapper">
                              <div className="inner-card card-label">
                                {" "}
                                {moment(selectedDate).format(
                                  "MMMM Do YYYY"
                                )}{" "}
                              </div>
                              <Select
                                labelId="demo-simple-select-label"
                                placeholder="select minutes"
                                value={intervalData}
                                onChange={handleChange}
                                className="info-text-padding"
                              >
                                {
                                  <MenuItem
                                    value={intervalData}
                                    disabled
                                    selected
                                  >
                                    Select Interval
                                  </MenuItem>
                                }
                                {optionsList.map((option: number) => (
                                  <MenuItem value={option}>
                                    {disInterval(option)}
                                  </MenuItem>
                                ))}
                              </Select>
                              <ul>
                                <Card className="card">
                                  {slots && slots.length > 0 ? (
                                    slots.map((slot: any, index: number) => (
                                      <Card
                                        className="inner-card"
                                        onClick={() => {
                                          onSelect(slot, index);
                                        }}
                                        style={{
                                          backgroundColor:
                                            index === selectedIndex
                                              ? "#FF0990"
                                              : "#FAF4F7",
                                          color:
                                            index === selectedIndex
                                              ? "white"
                                              : "black",
                                          boxShadow: "none",
                                        }}
                                      >
                                        <MenuItem
                                          className={
                                            index === selectedIndex
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {slotNumber(slot?.value1)}{" "}
                                          {slotEx(slot.value1)} -{" "}
                                          {slotNumber(slot?.value2)}{" "}
                                          {slotEx(slot.value2)}
                                        </MenuItem>
                                      </Card>
                                    ))
                                  ) : (
                                    <div className="no-slot">No Slots</div>
                                  )}
                                </Card>
                              </ul>
                              <button
                                className="submit-btn"
                                onClick={() => _handleSubmit()}
                                disabled={loading}
                              >
                                {loading ? (
                                  <CircularProgress size={15} />
                                ) : (
                                  "Confirm"
                                )}
                              </button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <div className="calendar-wrapper">
                        <h2>Verify Your Information</h2>
                        <div className="info-wrapper">
                          <div className="info-content">
                            <Avatar
                              src={_.get(
                                activeRoomDetails,
                                "receiverProfile",
                                ""
                              )}
                              size="xsm"
                            />

                            <div className="info-text">
                              {_.get(activeRoomDetails, "receiverName", "")}
                            </div>
                          </div>
                          <div className="info-content">
                            <Avatar src={CalenderIcon} size="xsm" />
                            <div className="info-text">
                              {slotNumber(
                                _.get(formObject, "slot", "").split("-")[0]
                              )}
                              {slotEx(
                                _.get(formObject, "slot", "").split("-")[0]
                              )}{" "}
                              -{" "}
                              {slotNumber(
                                _.get(formObject, "slot", "").split("-")[1]
                              )}
                              {slotEx(
                                _.get(formObject, "slot", "").split("-")[1]
                              )}{" "}
                              &nbsp;&nbsp;
                              {moment(_.get(formObject, "date", "")).format(
                                "MMMM Do YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="chat-form1">
                            <form
                              className={"send-message-form"}
                              id="form2"
                              noValidate
                              onSubmit={handleSubmit2(onSubmit1)}
                            >
                              <div className="form-wrappper">
                                <AsyncSelect
                                  onStartSearching={searchLocations}
                                  name="location_id"
                                  placeholder="Search time zone..."
                                  externalLabel={{
                                    label: "Location",
                                  }}
                                  required={true}
                                  validationObj={errors2}
                                  control={control}
                                  noOptionsMessage="No matching locations found"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Please select location from list",
                                    },
                                  }}
                                />
                              </div>
                              <div className="form-wrappper">
                                <p className="form-group-label">
                                  Agenda of call
                                </p>
                                <Input
                                  type="text"
                                  name="agenda_of_call"
                                  placeholder="Agenda of call"
                                  validationObj={errors2}
                                  InputProps={{}}
                                  inputRef={register2({
                                    required: {
                                      value: true,
                                      message: "Please enter agenda of call",
                                    },
                                  })}
                                />
                              </div>
                              <div className="form-wrappper">
                                <p className="form-group-label">Email</p>
                                <Input
                                  name="email"
                                  placeholder="Email"
                                  validationObj={errors2}
                                  inputRef={register2({
                                    required: {
                                      value: true,
                                      message: "Please enter email address",
                                    },
                                    pattern: {
                                      value: /\S+@\S+\.\S+/,
                                      message: "Enter valid email address",
                                    },
                                  })}
                                />
                              </div>
                              <div className="meeting-type form-wrappper">
                                <p className="form-group-label">Meeting Type</p>
                                <Radio
                                  name="meeting_type"
                                  control={control}
                                  validationObj={errors2}
                                  labelPlacement="end"
                                  required={true}
                                  onChange={(e) => {
                                    _handleRadio(e);
                                  }}
                                  options={radioOptions}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Please select a meeting type",
                                    },
                                  }}
                                />
                              </div>
                              {radio === "video_meeting" && (
                                <div className="form-wrappper">
                                  <p className="form-group-label">Video Link</p>
                                  <Input
                                    type="text"
                                    name="video_link"
                                    placeholder="Video Link"
                                    validationObj={errors2}
                                    inputRef={register2({
                                      required: {
                                        value: true,
                                        message: "Please enter video link",
                                      },
                                    })}
                                  />
                                </div>
                              )}
                              {radio === "phone_call" && (
                                <div className="form-wrappper">
                                  <p className="form-group-label">
                                    Phone Number
                                  </p>
                                  <Input
                                    type="number"
                                    name="phone_Number"
                                    placeholder="Phone Number"
                                    validationObj={errors2}
                                    inputRef={register2({
                                      required: {
                                        value: true,
                                        message: "Please enter phone number",
                                      },
                                      minLength: {
                                        value: 10,
                                        message:
                                          "Phone number must be 10 characters",
                                      },
                                    })}
                                  />
                                </div>
                              )}
                              <div className="additional-notes">
                                <p className="form-group-label">
                                  Additional Notes
                                </p>
                                <section>
                                  <Input
                                    type="text"
                                    name="additional_notes"
                                    placeholder="Additional Notes"
                                    validationObj={errors2}
                                    inputRef={register2({
                                      required: {
                                        value: true,
                                        message: "Please enter notes",
                                      },
                                    })}
                                  />
                                </section>
                              </div>
                              <Grid
                                container
                                spacing={3}
                                className="upload-img-wrapper"
                              >
                                <Grid item xs={6}>
                                  <Card className="upload-image">
                                    {bannerImageName}
                                  </Card>
                                  <span className="banner-image-info">
                                    Maximum file size 5MB Only doc, zip, pdf,
                                    and img file
                                  </span>
                                </Grid>
                                <Grid item xs={5}>
                                  <section>
                                    <Input
                                      type="file"
                                      name="image"
                                      placeholder=""
                                      validationObj={errors2}
                                      onChange={(e) => setBanner(e)}
                                      inputRef={register2({
                                        required: {
                                          value: true,
                                          message: "Please upload image",
                                        },
                                      })}
                                    />
                                  </section>
                                </Grid>
                              </Grid>
                              <div className="form-group btn-group">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="text-uppercase"
                                >
                                  Send
                                </Button>
                                <Button
                                  color="secondary"
                                  className="text-uppercase"
                                  onClick={() => {
                                    cancelSubmission();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div className="form-group text-center"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <MessagesList
                      messagesList={messagesList}
                      activeRoomDetails={activeRoomDetails}
                      loadMoreMessages={() => loadMoreMessages()}
                    />
                    <div className="chat-form">
                      {/* <AttachmentIcon /> */}
                      {/* <Input inputRef={register} type="hidden" name="id" value={11} /> */}
                      <textarea
                        rows={4}
                        ref={messageInputRef}
                        value={messageText}
                        onChange={(e) =>
                          setMessageText(e?.currentTarget?.value || "")
                        }
                        onKeyUp={(e) => {
                          if (
                            messageSendType === "enterToSend" &&
                            e.key === "Enter"
                          ) {
                            e.preventDefault();
                            onSendMessage();
                          }
                        }}
                        placeholder="Type a message..."
                      />
                    </div>
                    <div className="message-send-action">
                      <div className="send-left">
                        <div className="emoji-wrapper" ref={emojiRef}>
                          <Emoji
                            onClick={() =>
                              setTogglePicker((prevState) => !prevState)
                            }
                          />
                          {togglePicker && (
                            <EmojiPicker
                              onEmojiClick={(e) =>
                                setMessageText(
                                  (prevMsg) => prevMsg + " " + e.emoji
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="send-right">
                        {messageSendType === "clickToSend" && (
                          <Button
                            aria-label="send"
                            className="message-submit-btn"
                            disabled={!messageText}
                            onClick={() => onSendMessage()}
                          >
                            Send
                          </Button>
                        )}
                        {messageSendType === "enterToSend" && (
                          <small>Press Enter to Send</small>
                        )}
                        <IconButton
                          aria-label="more"
                          size="small"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={handleOpenMenu}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorMenu}
                          keepMounted
                          open={openMenu}
                          classes={{ paper: "border-radius-md" }}
                          onClose={handleMenuClose}
                        >
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            className="pt-5 pb-5 pl-10 pr-10"
                            value={messageSendType}
                            onChange={(e) => {
                              dispatch(
                                setMessageSendType(e.currentTarget.value)
                              );
                              handleMenuClose();
                            }}
                          >
                            <FormControlLabel
                              value="enterToSend"
                              control={<MuiRadio />}
                              label={
                                <div className="flex-column">
                                  <span>Press Enter to Send</span>
                                  <small>Press Enter will send message</small>
                                </div>
                              }
                            />
                            <FormControlLabel
                              value="clickToSend"
                              control={<MuiRadio />}
                              label={
                                <div className="flex-column">
                                  <span>Click Send</span>
                                  <small>
                                    Clicking Send button will send message
                                  </small>
                                </div>
                              }
                            />
                          </RadioGroup>
                        </Menu>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="new-convo-msg">
                Select contact to start a conversation
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="new-msg-model"
        visible={showNewMessageModal}
        title="New message"
        onClose={() => _handleCloseNewMessageModal()}
      >
        <Grid container spacing={3} className="messages-modal">
          <Grid item xs={12}>
            <Input
              name="search"
              className="message"
              type="text"
              placeholder="Search"
              onChange={(e) => _handleSearchUsers(e)}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <ul className="user-msg-list slim-scrollbar">
              {!usersListLoading ? (
                usersList.length === 0 && usersListLoading === false ? (
                  <div className="text-center">No results found</div>
                ) : (
                  usersList.map((row, key) => (
                    <li
                      key={key}
                      className="d-flex"
                      onClick={() => _handleInitializeNewChat(row)}
                    >
                      <Avatar
                        src={_.get(row, "profile_image", "")}
                        size="xsm"
                      />
                      <div className="ml-15">
                        <span>{`${row.first_name} ${row.last_name}`}</span>
                        <p className="m-0 font-sm mt-1 text-gray">
                          {row.receiverJobTitle}
                        </p>
                      </div>
                    </li>
                  ))
                )
              ) : (
                [1, 2, 3].map((key) => (
                  <li key={key} className="d-flex">
                    <Skeleton variant="circle" width={40} height={40} />
                    <span className="ml-15">
                      <Skeleton variant="text" width={155} />
                    </span>
                  </li>
                ))
              )}
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <span
            className="span-link message-btn justify-center close-btn btn-secondary"
            onClick={() => _handleCloseNewMessageModal()}
          >
            Close
          </span>
        </Grid>
      </Modal>
    </>
  );
};

export default Messages;
