// Define Application's all routes here, Make sure they are grouped by with their parent

import { getAppDomain } from "../utils/helper"

export const adminPrefix: string = "admin"
export const companyPrefix: string = "employer"
export const candidatePrefix: string = "c"
export const candidateProfilePrefix: String = "profile"

const appDomainFull = getAppDomain({ protocol: true })
const appDomain = getAppDomain()

const appRoutes = {
  defaultUIPage: {
    title: "UI Page",
    path: "/ui-elements-employ-her-default-layout",
  },
  companyUIPage: {
    title: "UI Page",
    path: "/ui-elements-employ-her-company-layout",
  },
  Feed: {
    title: "NewsFeed",
    path: "/feeds",
  },
  HashTagFeed: {
    title: "NewsFeed",
    path: `/feeds/hash-tag/:slug`,
  },
  FeedDetails: {
    title: "Feeds",
    path: `/feeds/:slug`,
    generate: (slug: string) => `${slug}`,
    generatePath: (slug: string) => `${appDomain}feeds/${slug}`,
    generateFullPath: (slug: string) => `${appDomainFull}feeds/${slug}`,
  },
  MyNetwork: {
    title: "My Network",
    path: "/my-network",
  },

  // Home Routes
  home: {
    title: "Home",
    path: "/",
  },
  userLogin: {
    title: "Sign In",
    path: "/login",
  },
  userSignUp: {
    title: "Sign Up",
    path: "/sign-up",
  },
  userVerify: {
    title: "Verify",
    path: "/verify/:token",
    generatePath: (token: string = "") => `/verify/${token}`,
  },
  userForgotPassword: {
    title: "Forgot Password",
    path: "/forgot-password",
  },
  userResetPassword: {
    title: "Reset Your Password",
    path: "/reset-password/:token",
  },
  privacyPolicy: {
    title: "Privacy policy",
    path: "/privacy-hub/:tab",
    generatePath: () => "/privacy-hub/privacy-policy",
  },
  termsOfService: {
    title: "Terms of Service",
    path: "/privacy-hub/:tab",
    generatePath: () => "/privacy-hub/terms-of-service",
  },
  cookiePolicy: {
    title: "Cookie Policy",
    path: "/privacy-hub/:tab",
    generatePath: () => "/privacy-hub/cookie-policy",
  },
  contactUs: {
    title: "Contact Us",
    path: "/contact-us",
  },
  jobDetail: {
    title: "Job detail",
    path: "/jobs/:slug",
    generatePath: (slug: string) =>
      `${window.location.protocol}//${window.location.host}/jobs/${slug}`,
  },
  candidatePublicProfile: {
    path: `/${candidateProfilePrefix}/view/:slug`,
    generate: (slug: string) => `${slug}`,
    generatePath: (slug: string) =>
      `${appDomain}${candidateProfilePrefix}/view/${slug}`,
    generateFullPath: (slug: string) =>
      `${appDomainFull}${candidateProfilePrefix}/view/${slug}`,
  },

  // Company Routes

  CompanyPublicView: {
    title: "Company Public View",
    path: `/company/:companySlug/view`,
    generatePath: (companySlug: string) => `/company/${companySlug}/view`,
  },
  CompanyPublicViewJobs: {
    title: "Company Public View Jobs",
    path: `/company/:companySlug/view/jobs`,
    generatePath: (companySlug: string) => `/company/${companySlug}/view/jobs`,
  },
  CompanyPublicViewFeed: {
    title: "Company Public View Feed",
    path: `/company/:companySlug/view/feed`,
    generatePath: (companySlug: string) => `/company/${companySlug}/view/feed`,
  },
  companyDashboard: {
    title: "Company",
    path: `/${companyPrefix}/:companySlug/dashboard`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/dashboard`,
  },
  companyBasicInfo: {
    title: "Basic Info",
    path: `/${companyPrefix}/basic-info`,
  },
  companyAdminInviteBasicInfo: {
    title: "Basic Info",
    path: `/${companyPrefix}/admin-basic-info/:token`,
  },
  companyCandidates: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates`,
  },
  companyCandidateFavorite: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/favorite`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/favorite`,
  },
  companyCandidateQualified: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/qualified`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/qualified`,
  },
  companyCandidateInterviewed: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/interviewed`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/interviewed`,
  },
  companyCandidateContacted: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/contacted`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/contacted`,
  },
  companyCandidateScreening: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/screening`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/screening`,
  },
  companyCandidateHired: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/hired`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/hired`,
  },
  companyCandidateRejected: {
    title: "Candidates",
    path: `/${companyPrefix}/:companySlug/candidates/rejected`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/candidates/rejected`,
  },
  companyJobs: {
    title: "Jobs",
    path: `/${companyPrefix}/:companySlug/jobs`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/jobs`,
  },
  companyActiveJobs: {
    title: "Jobs",
    path: `/${companyPrefix}/:companySlug/jobs/active`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/jobs/active`,
  },
  companyJobsArchive: {
    title: "Jobs",
    path: `/${companyPrefix}/:companySlug/jobs/archive`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/jobs/archive`,
  },
  companyJobsDrafts: {
    title: "Jobs",
    path: `/${companyPrefix}/:companySlug/jobs/drafts`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/jobs/drafts`,
  },
  companyPage: {
    title: "Page",
    path: `/${companyPrefix}/:companySlug/page`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/page`,
  },
  companyPageHome: {
    title: "Page",
    path: `/${companyPrefix}/:companySlug/page/home`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/page/home`,
  },
  companyPagePeople: {
    title: "Page",
    path: `/${companyPrefix}/:companySlug/page/people`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/page/people`,
  },
  companyPageJobs: {
    title: "Page",
    path: `/${companyPrefix}/:companySlug/page/jobs`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/page/people`,
  },
  companyPageNews: {
    title: "Page",
    path: `/${companyPrefix}/page/news`,
  },
  companyPageEvents: {
    title: "Page",
    path: `/${companyPrefix}/page/events`,
  },
  candidatePage: {
    title: "Page",
    path: `/profile/view/:slug`,
    generatePath: (slug: string) => `/profile/view/${slug}`,
    generateFullPath: (slug: string) =>
      `${appDomainFull}${candidateProfilePrefix}/view/${slug}`,
  },
  companyPublicPage: {
    title: "Page",
    path: `/${companyPrefix}/profile/view/:slug`,
  },
  companyPublicPageHome: {
    title: "Page",
    path: `/${companyPrefix}/profile/home/:slug`,
    generatePath: (slug: string) =>
      `${appDomainFull}${companyPrefix}/profile/home/${slug}`,
  },
  companyPublicPagePeople: {
    title: "Page",
    path: `/${companyPrefix}/profile/people/:slug`,
    generatePath: (slug: string) =>
      `${appDomainFull}${companyPrefix}/profile/people/${slug}`,
  },
  companyPublicPageJobs: {
    title: "Page",
    path: `/${companyPrefix}/profile/jobs/:slug`,
    generatePath: (slug: string) =>
      `${appDomainFull}${companyPrefix}/profile/jobs/${slug}`,
  },
  companyPublicPageNews: {
    title: "Page",
    path: `/${companyPrefix}/profile/news/:slug`,
  },
  companyPublicPageEvents: {
    title: "Page",
    path: `/${companyPrefix}/profile/events/:slug`,
  },
  companyAdmins: {
    title: "Jobs",
    path: `/${companyPrefix}/:companySlug/admins`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/admins`,
  },
  marketingSolution: {
    title: "MarketingSolution",
    path: `/${companyPrefix}/:companySlug/marketing-solution`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution`,
  },
  uploadContact: {
    title: "UploadContact",
    path: `/${companyPrefix}/:companySlug/marketing-solution/contact-upload`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution/contact-upload`,
  },
  manuallyContact: {
    title: "ManuallyContact",
    path: `/${companyPrefix}/:companySlug/marketing-solution/contact-manually`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution/contact-manually`,
  },
  createTags: {
    title: "CreateTags",
    path: `/${companyPrefix}/:companySlug/marketing-solution/create-tag`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution/create-tag`,
  },
  reviewInfo: {
    title: "ReviewInfo",
    path: `/${companyPrefix}/:companySlug/marketing-solution/review-info`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution/review-info`,
  },
  contactList: {
    title: "ContactList",
    path: `/${companyPrefix}/:companySlug/marketing-solution/contact-list`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/marketing-solution/contact-list`,
  },
  companyAdminsInHouse: {
    title: "Jobs",
    path: `/${companyPrefix}/admins/in-house`,
  },
  companyAdminsAgencies: {
    title: "Jobs",
    path: `/${companyPrefix}/admins/agencies`,
  },
  companyMessages: {
    title: "Messages",
    path: `/${companyPrefix}/:companySlug/messages`,
    generatePath: (companySlug: string) =>
      `/${companyPrefix}/${companySlug}/messages`,
  },
  // companyMessageCandidates: {
  //   title: "Messages",
  //   path: `/${companyPrefix}/messages/candidates`
  // },
  // companyMessageAdmins: {
  //   title: "Messages",
  //   path: `/${companyPrefix}/messages/admins`
  // },
  // companyMessageMessageRequests: {
  //   title: "Messages",
  //   path: `/${companyPrefix}/messages/message-requests`
  // },
  companySettings: {
    title: "Settings",
    path: `/${companyPrefix}/settings`,
  },

  //Search and Connect
  searchAndConnect: {
    title: "Search And Connect",
    path: `/connect`,
  },

  connectionNotifications: {
    title: "Notifications",
    path: `/notifications`,
  },

  companyNotifications: {
    title: "Company Notifications",
    path: `/${companyPrefix}/notifications`,
  },

  // Admin

  // Candidates
  candidateProfile: {
    title: "createProfile",
    path: `/${candidateProfilePrefix}`,
  },

  candidateVideos: {
    title: "Video Studio",
    path: "/video-studio",
  },

  candidateCreateVideo: {
    title: "Create Video",
    path: "/video-studio/create",
  },
  candidateEditVideo: {
    title: "Edit Video",
    path: "/video-studio/edit/:id",
  },
  candidateMessages: {
    title: "Messages",
    altPath: `/messages`,
    path: `/messages/:chatRoom?`,
  },

  candidateMyMeetings: {
    title: "My Meetings",
    path: `/my-meetings`,
  },

  candidateProfileSettings: {
    title: "ProfileCandidateSettings",
    path: `/candidate-settings/:type`,
    generatePath: (type: string) => `/candidate-settings/${type}`,
  },

  // Candidate Job Search
  candidateJobSearch: {
    title: "Careers",
    path: `/careers`,
  },

  candidateConnections: {
    title: "Connections",
    path: `/connections`,
  },

  candidateConnectionsType: {
    title: "Connections",
    path: `/connections/:type`,
    generatePath: (type: string) => `/connections/${type}`,
  },

  candidateJobHub: {
    title: "Job hub",
    path: `/job-hub/:type`,
    generatePath: (type: string) => `/job-hub/${type}`,
  },

  // Scheduler
  scheduler: {
    title: "Scheduler",
    path: `/scheduler`,
  },
  publicScheduler: {
    title: "Public Scheduler",
    path: `/scheduler/:candidateSlug`,
  },
  schedulerSlug: {
    title: "scheduler Slug",
    path: `/scheduler/:candidateSlug/:link`,
  },
  // Etc...
  // anonymous: {
  //   title: "anonymous",
  //   path: `/*`
  // }

  notFoundError: {
    title: "Not Found",
    path: `/errors/not-found`,
  },
  internalError: {
    title: "Internal Server",
    path: `/errors/internal-server`,
  },
  unauthorizedError: {
    title: "Unauthorized Error",
    path: `/errors/unauthorized`,
  },
  candidateProfileView: {
    title: "Candidate Profile",
    path: `/${candidateProfilePrefix}/view`,
  },
  companyHome: {
    title: "Company Home",
    path: `${companyPrefix}/test-home`,
  },
  article: {
    title: "Article",
    path: "/article"
  },
  articleUpdate: {
    title: "ArticleUpdate",
    path: `/article/update/:id`,
    generatePath: (id: string = "") => `/article/update/${id}`,
  },
  getArticle: {
    title: "ArticleData",
    path: `/article-data/:id`,
    generatePath: (id: string = "") => `/article-data/${id}`
  },
  viewArticle: {
    title: "ArticleView",
    path: `/article-view/:slug`,
    generatePath: (slug: string) => `/article-view/${slug}`,
    // generateFullPath: (slug: string) => `${appDomainFull}feeds/${slug}`,

  },

  Error404: {
    title: "Error 404",
    path: "/*",
  },
}

export default appRoutes
