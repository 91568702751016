import React, { useEffect, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { get } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Input } from "../../components/common";
import {
  forgotPasswordRequest,
  resetForgotPassword,
  resetForgotPasswordMessages,
} from "../../reducers/auth/forgotPassword.reducer";
import { FlashMessage, MessageHelper } from "../../components/common";
import appRoutes from "../../routes/app.routes";
import { ReactComponent as PersonIcon } from "../../assets/svg/person.svg";
import { Helmet } from "react-helmet";
import { rootReducersState } from "../../reducers";
import { InputAdornment } from "@material-ui/core";
import Affirmation from "../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const _ = { get };

type Inputs = {
  email: string;
};

const ForgotPassword = (props: any) => {
  // Hooks
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const dispatch = useDispatch();

  const forgotPassword = useSelector(
    ({ auth }: rootReducersState) => auth.forgotPassword
  );
  const successMessage = _.get(forgotPassword, "successMessage", "");
  const errorMessage = _.get(forgotPassword, "errorMessage", "");
  const loadingFlag = _.get(forgotPassword, "loading", false);
  const forgotPasswordFlag = _.get(forgotPassword, "flag", false);

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const tokenData = _.get(sessionReducer, "currentUser", {});

  const { register, handleSubmit, watch, errors } = useForm<Inputs>();

  const watchEmail = watch("email"); // Watch email change to reset the success or error message

  // Verify the captcha
  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return false;
      }

      return await executeRecaptcha("forgot_password");
    } catch (error) {
      return "";
    }
  }, [executeRecaptcha]);

  // Did mount effect
  useEffect(() => {
    // Check if user is already logged in
    const userRole = _.get(tokenData, "role", "");
    if (userRole) {
      history.push("/");
    }

    // Did unmounted, reset the store
    return () => {
      dispatch(resetForgotPassword());
    };

    // eslint-disable-next-line
  }, []);

  // Clear the error or success message only if feasible
  useEffect(() => {
    if (errorMessage || successMessage) {
      dispatch(resetForgotPasswordMessages());
    }

    // eslint-disable-next-line
  }, [watchEmail]);

  // Hook, once user forgot password sent successfully
  useEffect(() => {
    if (forgotPasswordFlag === true) {
      FlashMessage(successMessage);
      dispatch(resetForgotPassword());
      history.push(appRoutes.userLogin.path);
    }

    // eslint-disable-next-line
  }, [forgotPasswordFlag]);

  const onSubmit = async (formData) => {
    const captchaToken = await handleReCaptchaVerify();

    dispatch(
      forgotPasswordRequest({
        email: _.get(formData, "email", ""),
        token: captchaToken,
        action: "forgot_password",
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>employHER | Forgot Password</title>
      </Helmet>
      <div className="auth-wrapper">
        <div className="left-part">
          <Affirmation />
        </div>
        <div className="right-part">
          <div>
            <div className="back-website">
              <div className="back">
                <Link to={appRoutes.userLogin.path}>
                  {" "}
                  <ArrowBackIosIcon />{" "}
                </Link>
              </div>
              <Link to={appRoutes.userLogin.path}>Back to website</Link>
            </div>
            <div className="auth-card auth-login-wrapper forgot-password-wrapper">
              <h2 className="auth-card-title">Forgot Password</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <p className="form-group-label">Email</p>
                  <Input
                    name="email"
                    placeholder="Email"
                    validationObj={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter email address",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Enter valid email address",
                      },
                    })}
                  />
                </div>
                <div className="form-group btn-group">
                  {errorMessage && (
                    <MessageHelper type="error" text={errorMessage} />
                  )}
                  <Button color="primary" type="submit" loading={loadingFlag}>
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
