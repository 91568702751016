import React, { useState, useEffect, useRef } from "react";
import "quill-mention";
import _ from 'lodash';
import { Avatar } from "@material-ui/core";
import Image from "../../../components/common/Image";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
import { useParams } from "react-router-dom";
import Emoji from "../../Messages/components/emoji";
import EmojiPicker from "emoji-picker-react";
import { useForm } from "react-hook-form";
import dots from "../../../assets/svg/dots.svg";
import { useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import FlashMessage from "../../../components/common/FlashMessage";

interface Article {
  seo_title: string;
  seo_description: string;
  title: string;
  content: string;
  job_title: string;
  user_name: string;
  cover_image: string;
  id: number
};

interface ArticleComment {

  post_id: string,
  id: number,
  parent_id: number
  comment_text: string,
  user_id: number
  reply_comments: {
    id: number,
    parent_id: number,
    user_id: number,
    comment_text: string,
    post_id: string,
    user: {
      first_name: string,
      last_name: string
    },
    candidateProfile: {
      job_title_custom: string,
      jobTitle: {
        title: string;
      }
    },
  }[],
  user: {
    first_name: string,
    last_name: string
  },
  candidateProfile: {
    job_title_custom: string,
    jobTitle: {
      title: string;
    }
  },
};

interface AddComment {
  comment_text: string,
  post_id: string,
  parent_id: string
}


function ArticleView() {

  const [viewData, setViewData] = useState<Article>({} as Article);
  const [page, setPage] = useState<number>(2);
  const params = useParams();
  const slug = _.get(params, "slug", "");
  const [togglePicker, setTogglePicker] = useState<boolean>(false);
  const emojiRef = useRef<any>(null);
  const PostId = viewData.id;
  const [comments, setComments] = useState<ArticleComment[]>([]);
  const [replyComment, setReplyComment] = useState<any>({});
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<any>({ comment_text: "" });

  const [editComment, setEditComment] = useState<any>({});
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const currentUserId = _.get(sessionReducer, "currentUser.id", "");

  const hideEmojis = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setTogglePicker(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", hideEmojis, true);
    return () => {
      document.removeEventListener("click", hideEmojis, true);
    };
  }, []);


  const {
    register
  } = useForm<AddComment>();


  useEffect(() => {
    fetchArticle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchArticle = async () => {
    try {
      const oldData = await httpRequest().get(API_URLS.viewArticle(slug));
      const firstName = oldData.data.data.user.first_name;
      const lastName = oldData.data.data.user.last_name;
      const username = firstName + " " + lastName;
      const comment = oldData?.data?.data?.post_comments
      const jobTitle = oldData?.data?.data?.candidateProfile?.job_title_custom || oldData?.data?.data?.candidateProfile?.jobTitle?.title;
      const data = {
        id: oldData.data.data.id,
        title: oldData.data.data.title,
        content: oldData.data.data.content || "",
        seo_title: oldData.data.data.seo_title || "",
        seo_description: oldData.data.data.seo_description || "",
        job_title: jobTitle,
        user_name: username,
        cover_image: oldData.data.data.va_cover_image || ""
      }
      setViewData(data);
      setComments(comment);
    } catch (error) {
      console.log(error);
    }
  }
  const fetchArticleComment = async () => {
    try {
      const getComment = await httpRequest().get(`${API_URLS.fetchArticleComment}/?id=${PostId}&page=${page}`)
      const allComment = getComment.data.data
      setComments((prevState) => ([...prevState, ...allComment]))
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchArticleReply = async (id) => {
    try {
      const reply = await httpRequest().get(`${API_URLS.fetchArticleReply}/?post_id=${PostId}&parent_id=${id}&page=${page}`);
      let allReply = reply.data.data
      setComments(comments.map((comment: any) => {
        if (parseInt(comment.id) === parseInt(id)) {
          return {
            ...comment,
            reply_comments: [...comment.reply_comments, ...allReply],
          };
        }
        return comment;
      }));
      setPage((prePage) => prePage + 1)
    } catch (e) {
      console.log(e)
    }
  }

  const addComment = async (e: any, id?: number) => {
    try {
      const parentId = id;
      const flag = parentId ? "reply" : "comment";
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode === 13 && !e.shiftKey) {

        const payload = {
          'comment_text': commentText.comment_text.trim(),
          'parent_id': parentId,
          'post_id': PostId
        };


        if (flag === "comment") {
          const { data } = await httpRequest().post<{
            data: ArticleComment;
          }>(`${API_URLS.addComment}`, payload);
          const newComment = data.data
          setComments([newComment, ...comments])
        } else {

          const { data } = await httpRequest().post<{
            data: ArticleComment;
          }>(`${API_URLS.addComment}`, payload);

          const newComment = data.data
          setComments(comments.map((comment: any) => {
            if (parseInt(comment.id) === id) {
              return {
                ...comment,

                reply_comments: [newComment, ...comment.reply_comments],
              };
            }
            return comment;
          }));
        }
      }
      setCommentText(null);

    } catch (error) {
      FlashMessage(
        _.get(error, "message", "Unable to add comment")
      );
    }
  }
  const likeComment = async (id) => {
    const payload = {
      'post_id': PostId,
      'comment_id': id
    }
    await httpRequest().post(`${API_URLS.likeComment}`, payload);
  }
  const handleReply = (commentText: any) => {
    setReplyComment(commentText);
  };
  const handleEdit = (commentText: any) => {
    setEditComment(commentText);
  };

  const editComments = async (e: any, id: number, parent_id?: any) => {

    const payload = {
      comment_text: commentText.comment_text,
      id: id,
      post_id: PostId,
      parent_id: parent_id
    }

    const data = await httpRequest().put<{
      data: AddComment
    }>(API_URLS.editComment, payload);

    const comment_text = data.data.data.comment_text || ''
    const parentId = parent_id

    if (!parentId) {
      setComments(comments.map((comment: any) => {
        if (parseInt(comment.id) === id) {
          return {
            ...comment, comment_text: comment_text,
          };
        }
        return comment;
      }));
    } else {
      const replyId = id;
      setComments((prevState) => prevState.map((comment: any) => {
        if (parseInt(comment.id) === parseInt(parentId)) {
          let row = comment.reply_comments.map((row) => {
            if (row.id === replyId) {
              return {
                ...row,
                comment_text: comment_text
              };
            } else {
              return row;
            }
          });
          return {
            ...comment,
            reply_comments: row || []
          }
        }
        return comment;
      }))

    }
  }
  const deleteComment = async (id: any, parent_id?: any) => {
    const commentId = id;
    const parentId = parent_id;
    await httpRequest().delete<{
      data: AddComment;
    }>(`${API_URLS.deleteComment}/?id=${commentId}&post_id=${PostId}&parent_id=${parentId}`);

    if (!parentId) {
      const commentData = [...comments];
      const filterData = commentData.filter(comment => comment.id !== id);
      setComments(filterData)
    } else {
      const replyId = id;
      setComments((prevState) => prevState.map((comment: any) => {
        if (parseInt(comment.id) === parseInt(parentId)) {
          const replies = comment.reply_comments.filter(row => row.id !== replyId)
          return {
            ...comment,
            reply_comments: [...replies],
          };
        }
        return comment;
      }))

    }

  }
  return (

    <div className="article-post-container">
      <div className="article-post-sub-container">
        <div className="article-post">
          <Image src={viewData?.cover_image} alt="media image" />{" "}
        </div>
        <div >
          <h1>{viewData?.title || ''}</h1>
        </div>
        <div className="article-post-avatar">
          <Avatar
            src={viewData?.cover_image}>
          </Avatar>
          <div className="article-post-user">
            <span className="span-user">{viewData?.user_name || ''}</span>
            <span className="span-job-title">{viewData?.job_title || ''}</span>
          </div>

        </div>
        <div className="article-post-description"
          dangerouslySetInnerHTML={{
            __html: (viewData?.content),
          }}
        />
        <div>
          <div className="article-comment">
            <Avatar></Avatar>
            <div className="comment-input-wrapper">
              <textarea
                name="comment_text"
                placeholder="Add a comment..."
                onKeyDown={(e) => addComment(e)}
                value={commentText?.comment_text}
                onChange={(e) => {
                  const data = e?.currentTarget?.value || "";
                  setCommentText((prevState) => ({
                    ...prevState,
                    comment_text: data
                  }))
                }}

                ref={register({
                  maxLength: {
                    value: 1000,
                    message: "Can not exceed more then 1000 characters",
                  },
                  required: {
                    value: true,
                    message: "Comment can't be empty",
                  },
                })}
              />
              <div className="submit-emoji-wrapper">
                <div className="emoji-icon-wrapper" ref={emojiRef}>
                  <Emoji onClick={() => setTogglePicker(!togglePicker)} />

                  {togglePicker && (
                    <EmojiPicker
                      onEmojiClick={(e) => {
                        const data = commentText?.comment_text + e.emoji
                        setCommentText((prevState) => ({
                          ...prevState, comment_text: data
                        }))
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {comments.map((comment) => (
            <div className="comment">
              <div className="comment-list">
                <Avatar></Avatar>
                <div className="comment-list-data">
                  {currentUserId === comment.user_id && (<div
                    onClick={() =>
                      setShowMenu((prevState) => !prevState)
                    }
                    className="feed-header-more "
                  >
                    <Image
                      src={dots}
                      alt="postImage"
                      className="dot-icon"
                    />  {showMenu && (
                      <ul>
                        <li
                          onClick={() => {
                            deleteComment(comment.id)
                          }}
                        >
                          Delete Comment
                        </li>
                        <li>
                          copy link to comment
                        </li>

                        <li
                          className="font-sm cursor-pointer"
                          onClick={() => {
                            handleEdit(comment)
                          }}
                        >edit
                        </li>
                        <li>
                          Report
                        </li>
                      </ul>
                    )}</div>
                  )}<h6 className="user-name">{comment?.user?.first_name + " " + comment?.user?.last_name}</h6>
                  <div className="user-field">{comment?.candidateProfile?.job_title_custom || comment?.candidateProfile?.jobTitle?.title}</div>
                  <div className="data">{comment?.comment_text}</div>
                  <div>
                    {_.get(editComment, "id") === comment.id && (
                      <div className="edit">
                        <div className="comment-input-wrapper">
                          <textarea className="data"
                            id="title"
                            placeholder={comment?.comment_text}
                            value={commentText?.comment_text}
                            onKeyDown={(e) => editComments(e, comment.id)}
                            onChange={(e) => {
                              const data = e?.currentTarget?.value || "";
                              setCommentText((prevState) => ({
                                ...prevState,
                                comment_text: data
                              }))
                            }}
                          ></textarea>
                          <div className="submit-emoji-wrapper">
                            <div className="emoji-icon-wrapper" ref={emojiRef}>
                              <Emoji onClick={() => setTogglePicker(!togglePicker)} />

                              {togglePicker && (
                                <EmojiPicker
                                  onEmojiClick={(e) => {

                                    const data = commentText?.comment_text + e.emoji
                                    setCommentText((prevState) => ({
                                      ...prevState, comment_text: data
                                    }))
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="comment-like-reply">
                <button className="btn-like" onClick={() => likeComment(comment.id)}>like</button>
                <span
                  className="font-sm cursor-pointer"
                  onClick={() => {
                    handleReply(comment);
                  }}
                >
                  | Reply
                </span>

                <div>
                  {_.get(replyComment, "id") === comment.id && (
                    <div className="article-comment">
                      <Avatar></Avatar>
                      <div className="comment-input-wrapper">
                        <textarea
                          name="content"
                          placeholder="Add a reply..."
                          onKeyDown={(e) => addComment(e, comment.id)}
                          value={commentText?.comment_text}
                          onChange={(e) => {
                            const data = e?.currentTarget?.value || "";
                            setCommentText((prevState) => ({
                              ...prevState,
                              comment_text: data
                            }))
                          }}
                          ref={register({
                            maxLength: {
                              value: 1000,
                              message: "Can not exceed more then 1000 characters",
                            },
                            required: {
                              value: true,
                              message: "Reply can't be empty",
                            },
                          })}
                        />
                        <div className="submit-emoji-wrapper">
                          <div className="emoji-icon-wrapper" ref={emojiRef}>
                            <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                            {togglePicker && (
                              <EmojiPicker
                                onEmojiClick={(e) => {
                                  const data = commentText?.comment_text + e.emoji
                                  setCommentText((prevState) => ({
                                    ...prevState, comment_text: data
                                  }))
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {
                comment?.reply_comments?.map((reply) => (
                  <div className="comment-reply">
                    <div className="comment-sub-reply">
                      <Avatar></Avatar>
                      <div className="comment-reply-data">
                        {currentUserId === reply.user_id && (
                          <div
                            onClick={() =>
                              setShowMenu((prevState) => !prevState)
                            }
                            className="feed-header-more "
                          >
                            <Image
                              src={dots}
                              alt="postImage"
                              className="dot-icon"
                            />  {reply.id && showMenu && (
                              <ul>
                                <li
                                  onClick={() => {
                                    deleteComment(reply.id, reply.parent_id)
                                  }}
                                >
                                  Delete Comment
                                </li>
                                <li
                                  className="font-sm cursor-pointer"
                                  onClick={() => {
                                    handleEdit(reply)
                                  }}
                                >edit
                                </li>
                                <li>
                                  Report
                                </li>
                              </ul>
                            )}</div>)}

                        <h6 className="user-name">{reply?.user?.first_name + " " + reply?.user?.last_name}</h6>
                        <div className="user-field">{reply?.candidateProfile?.job_title_custom || reply?.candidateProfile?.jobTitle?.title}</div>
                        <div className="data">{reply?.comment_text}
                          <div>
                            {_.get(editComment, "id") === reply.id && (
                              <div className="comment-input-wrapper">
                                <textarea className="data"
                                  id="title"
                                  placeholder={reply?.comment_text}
                                  value={commentText?.comment_text}
                                  onKeyDown={(e) => editComments(e, reply.id, reply.parent_id)}
                                  onChange={(e) => {
                                    const data = e?.currentTarget?.value || "";
                                    setCommentText((prevState) => ({
                                      ...prevState,
                                      comment_text: data
                                    }))
                                  }}
                                ></textarea>
                                <div className="submit-emoji-wrapper">
                                  <div className="emoji-icon-wrapper" ref={emojiRef}>
                                    <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                                    {togglePicker && (
                                      <EmojiPicker
                                        onEmojiClick={(e) => {
                                          const data = commentText?.comment_text + e.emoji
                                          setCommentText((prevState) => ({
                                            ...prevState, comment_text: data
                                          }))
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="comment-like-reply">
                      <button className="btn-like">like</button>
                      <span
                        className="font-sm cursor-pointer"
                        onClick={() => {
                          handleReply(reply);
                        }}
                      >
                        | Reply
                      </span>
                      <div>
                        {_.get(replyComment, "id") === reply.id && (
                          <div className="article-comment">

                            <Avatar></Avatar>
                            <div className="comment-input-wrapper">
                              <textarea
                                name="content"
                                placeholder="Add a reply..."
                                onKeyDown={(e) => addComment(e, reply.parent_id)}
                                value={commentText?.comment_text}
                                onChange={(e) => {
                                  const data = e?.currentTarget?.value || "";
                                  setCommentText((prevState) => ({
                                    ...prevState,
                                    comment_text: data
                                  }))
                                }}
                                ref={register({
                                  maxLength: {
                                    value: 1000,
                                    message: "Can not exceed more then 1000 characters",
                                  },
                                  required: {
                                    value: true,
                                    message: "reply can't be empty",
                                  },
                                })}
                              />
                              <div className="submit-emoji-wrapper">
                                <div className="emoji-icon-wrapper" ref={emojiRef}>
                                  <Emoji onClick={() => setTogglePicker(!togglePicker)} />
                                  {togglePicker && (
                                    <EmojiPicker
                                      onEmojiClick={(e) => {
                                        const data = commentText?.comment_text + e.emoji
                                        setCommentText((prevState) => ({
                                          ...prevState, comment_text: data
                                        }))
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <button
                        className="load-more-reply"
                        onClick={() => { fetchArticleReply(reply?.parent_id) }}
                      >load more reply</button>
                    </div>

                  </div>
                ))
              }

            </div>
          ))}
        </div>
        <div className="more-comment">
          <button className="load-more-comment"
            onClick={fetchArticleComment}
          >Load more comment</button>
        </div>
      </div>
    </div >
  );
}

export default ArticleView;
