import { Grid, IconButton } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FlashMessage, ConfirmDialog } from "../../../components/common";
import CommentPost from "./CommentPost";
import Image from "../../../components/common/Image";
import { useDispatch, useSelector } from "react-redux";
import { rootReducersState } from "../../../reducers";
import {
  changeReplyData,
  commentLikeRequest,
  deleteCommentFlagReset,
  deleteCommentRequest,
} from "../../../reducers/feeds/feedPost.reducer";
import { ReducerFlag } from "../../../types/reducer.types";
import dots from "../../../assets/svg/dots.svg";
import { ReactComponent as Heart } from "../../../assets/svg/heart.svg";
import { ReactComponent as HeartActive } from "../../../assets/svg/heart-active.svg";
import appRoutes from "../../../routes/app.routes";
import { convertUTCToLocal, openUrlInNewTab } from "../../../utils/helper";
import Avatar from "../../../components/common/AvtarImage";
function List({ data }) {
  const [editComment, setEditComment] = useState<any>({});
  const [commentIndex, setCommentIndex] = useState<number>(-1);
  const [commentId, setCommentId] = useState({});

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  );
  const dispatch = useDispatch();

  const currentUserId = _.get(sessionReducer, "currentUser.id", "");
  const [openDeleteCommentModal, setOpenDeleteCommentModal] =
    useState<boolean>(false);
  const {
    deleteCommentMessage,
    deleteCommentLoading,
    deleteCommentFlag,
    replyDeleteFlag,
    replyData,
    replyList,
    commentLikeFlag,
    commentLikedData,
    likeErrorMessage,
  } = useSelector(({ postFeed }: rootReducersState) => postFeed.postFeed);

  const handleEdit = (data: any) => {
    setCommentIndex(-1);
    setEditComment(data);
  };

  const setComment = () => {
    setEditComment({});
  };

  const handleLike = (value: any) => {
    dispatch(
      commentLikeRequest({ post_id: value.post_id, comment_id: value.id })
    );
  };

  // delete message
  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(deleteCommentFlag)) {
      setOpenDeleteCommentModal(false);
      if (deleteCommentFlag === ReducerFlag.SUCCESS) {
      } else if (deleteCommentFlag === ReducerFlag.FAILED) {
        FlashMessage(deleteCommentMessage, "error");
      }
      dispatch(deleteCommentFlagReset());
    }

    // eslint-disable-next-line
  }, [deleteCommentFlag]);

  useEffect(() => {
    if (replyDeleteFlag === ReducerFlag.SUCCESS) {
      const oldData = replyList;
      const newData = oldData.map((item) => {
        if (parseInt(item.comment_id) === parseInt(replyData.parentId)) {
          let rows = item.rows.filter((reply: any) => {
            return reply.id !== replyData.id;
          });
          return {
            ...item,
            rows: rows || [],
          };
        } else {
          return item;
        }
      });
      setOpenDeleteCommentModal(false);
      dispatch(changeReplyData(newData));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyDeleteFlag]);

  // Hook, post like and unlike
  useEffect(() => {
    if (commentLikeFlag === ReducerFlag.SUCCESS) {
      if (commentLikedData.parentId !== null) {
        const oldData = replyList;
        const updatedData = oldData.map((item) => {
          if (item.comment_id === commentLikedData.parentId) {
            let rows = item.rows.map((row) => {
              if (row.id === commentLikedData.commentId) {
                return {
                  ...row,
                  liked: commentLikedData.liked,
                  comment_likes_count: commentLikedData.totalLikes,
                };
              } else {
                return row;
              }
            });
            return {
              ...item,
              rows: rows || [],
            };
          } else {
            return item;
          }
        });
        dispatch(changeReplyData(updatedData));
      }
    } else if (commentLikeFlag === ReducerFlag.FAILED) {
      FlashMessage(likeErrorMessage, "error");
    }

    // eslint-disable-next-line
  }, [commentLikeFlag]);

  return (
    <div>
      {data &&
        data?.rows &&
        data.rows.map((item: any, index) => {
          return (
            <Grid container key={index}>
              <Grid item xs={12}>
                <div className="comment-inner-wrapper">
                  <div className="user-img">
                    {_.isEmpty(item?.user?.profile_image) ? (
                      <IconButton
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                      >
                        <Avatar />
                      </IconButton>
                    ) : (
                      <Avatar src={item?.user?.profile_image} />
                    )}{" "}
                  </div>
                  <div className="comment-content-wrapper">
                    <div className="user-comment comment-card">
                      <h6
                        className="cursor-pointer link"
                        onClick={() => {
                          const slug = item?.user?.slug;
                          if (slug) {
                            const url =
                              appRoutes.candidatePublicProfile.generateFullPath(
                                slug
                              );
                            openUrlInNewTab(url);
                          }
                        }}
                      >
                        {item?.user?.first_name + " " + item?.user?.last_name}
                      </h6>
                      {_.get(editComment, "id") === item.id ? (
                        <CommentPost
                          data={data.rows}
                          item={item}
                          setComment={setComment}
                          reason={"edit-comment"}
                        />
                      ) : item.comment_type === 'image' ? (
                        <Image
                          src={item.comment_text}
                          height="50px"
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.comment_text?.replace(
                              /(\r\n|\n|\r)/gm,
                              "<br/>"
                            ),
                          }}
                        />
                      )}
                    </div>
                    <div className="mb-5 d-flex gap-1">
                      <span className="like-btn cursor-pointer">
                        {_.get(item, "liked", 0) === 1 ? (
                          <span className="like-active">
                            <HeartActive
                              width={13}
                              height={13}
                              onClick={() => {
                                handleLike(item);
                              }}
                            />
                          </span>
                        ) : (
                          <Heart
                            width={13}
                            height={13}
                            onClick={() => {
                              handleLike(item);
                            }}
                          />
                        )}
                      </span>{" "}
                      <span className="font-sm">
                        {_.get(item, "comment_likes_count", 0)}
                      </span>
                    </div>
                    <div className="comment-content-footer mt-10">
                      <span className="post-time">
                        {convertUTCToLocal(item.created_at)} ago
                      </span>
                      {currentUserId === item.user_id && (
                        <div
                          onClick={() => {
                            if (commentIndex > -1) {
                              setCommentIndex(-1);
                            } else {
                              setCommentIndex(index);
                            }
                          }}
                          className="feed-header-more"
                        >
                          <Image
                            src={dots}
                            alt="postImage"
                            className="dot-icon"
                          />
                          {commentIndex === index && (
                            <ul>
                              <li
                                onClick={() => {
                                  handleEdit(item);
                                }}
                              >
                                Edit Reply
                              </li>
                              <li
                                onClick={() => {
                                  setCommentId({
                                    postId: item.post_id,
                                    parentId: item.parent_id,
                                    id: item.id,
                                    isLast:
                                      data?.rows.length === 1 ? true : false,
                                  });
                                  setOpenDeleteCommentModal(true);
                                }}
                              >
                                Delete Reply
                              </li>
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          );
        })}
      {/* delete comment starts here */}
      <ConfirmDialog
        visible={openDeleteCommentModal}
        bodyText="Are you sure you want to delete this reply?"
        confirmText="Delete"
        loading={deleteCommentLoading}
        onConfirm={() => dispatch(deleteCommentRequest(commentId))}
        onCancel={() => setOpenDeleteCommentModal(false)}
      />
    </div>
  );
}

export default List;
