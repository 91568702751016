import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../components/common";
import { getCurrentTab } from "../../../utils/helper";
import { isEmpty, isUndefined } from "lodash";

const _ = { isEmpty, isUndefined };
interface ActionProps {
  handleClick: (type: string, text?: string) => any;
  showHomeTab?: boolean; // Not showing home page for company created from super admin, later add company claim flag
}

const Actions = (props: ActionProps) => {
  const { handleClick } = props;
  const { innerTab } = useSelector(({ company }: any) => company);
  const currentSubTab = getCurrentTab(props, true);
  const activeTab = _.isEmpty(innerTab)
    ? _.isUndefined(currentSubTab)
      ? "All"
      : currentSubTab
    : innerTab;
  const checktab = activeTab.toLowerCase();
  const showHomeTab = props.showHomeTab === false ? false : true;

  return (
    <div className="custom-tab-wrapper">
      <div className="custom-tab-left">
        <ul className="tab-menu">
          {showHomeTab && (
            <li>
              <Button
                className={`${checktab === "all" ? "active" : ""}`}
                onClick={() => handleClick("Home")}
              >
                {" "}
                Home{" "}
              </Button>
            </li>
          )}
          <li>
            <Button
              className={`${checktab === "jobs" ? "active" : ""}`}
              onClick={() => handleClick("Jobs")}
            >
              {" "}
              Jobs{" "}
            </Button>
          </li>
          <li>
           <Button className={`${checktab === 'feed' ? 'active' : ''}`} onClick={() => handleClick('feed')}> Feed </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Actions;
