import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  token: string | null;
  currentUser: Record<string, any>;
  currentCompany: {
    access_level: string;
    company_logo: string;
    company_name: string;
    privileges?: boolean;
    slug: string;
  };
}

const initialState: InitialState = {
  token: null,
  currentUser: {},
  currentCompany: {
    access_level: "",
    company_logo: "",
    company_name: "",
    privileges: false,
    slug: "",
  },
};

const sessionReducer = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setSessionTokenRequest: (state, { payload }) => {
      return { ...state, token: payload };
    },
    fetchSessionDataRequest: (state) => {
      return { ...state };
    },
    setSessionUserRequest: (state, { payload }) => {
      return { ...state, currentUser: payload };
    },
    sessionOutRequest: (state) => {
      return { ...state };
    },
    sessionOut: () => {
      return { ...initialState };
    },
    fetchSessionCompanyRequest: (state) => {
      return { ...state };
    },
    setCurrentCompanyRequest: (state, { payload }) => {
      return { ...state, currentCompany: payload };
    },
    updateBasicInformation: (state, { payload }) => {
      const currentUser = state.currentUser;
      const updatedData = _.merge({}, currentUser, payload);
      return { ...state, currentUser: updatedData };
    },
    updateConnectionReceivedCount: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          receivedCount: payload || 0,
        },
      };
    },
    setCandidateResumes: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          resumes: payload || [],
        },
      };
    },
    updateCandidateVisibilityRequest: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          profile_visibility: payload,
        },
      };
    },
    updateCandidateSlug: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          slug: payload,
        },
      };
    },
    updateCandidateResumeFile: (state, { payload }) => {
      const tokenUser = state.currentUser || {};
      const profile = state?.currentUser?.profile || {};
      let profileData = { ...profile, resume_file: payload };
      let currentUserData = { ...tokenUser, profile: profileData };

      return {
        ...state,
        currentUser: currentUserData,
      };
    },
    updateCandidateNotifications: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          alert_notifications: JSON.stringify(payload.alerts),
          activity_notifications: JSON.stringify(payload.activities),
          support_notifications: JSON.stringify(payload.supports),
        },
      };
    },
    updateCandidateUpdatedDate: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          password_updated_at: payload,
        },
      };
    },
    updateNotificationCount: (state, { payload }) => {
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          notificationCount: payload.notificationCount || 0,
          companyNotificationCount: payload.companyNotificationCount || 0,
        },
      };
    },
    updateMessageCount: (state, { payload }) => {
      console.log('payload', payload);
      const currentUser = state.currentUser || {};
      return {
        ...state,
        currentUser: {
          ...currentUser,
          candidateMessageCount: payload.candidateMessageCount || 0,
        },
      };
    },
  },
});

// Export actions
export const {
  setSessionTokenRequest,
  fetchSessionDataRequest,
  fetchSessionCompanyRequest,
  setSessionUserRequest,
  sessionOutRequest,
  sessionOut,
  setCurrentCompanyRequest,
  updateBasicInformation,
  updateConnectionReceivedCount,
  setCandidateResumes,
  updateCandidateVisibilityRequest,
  updateCandidateNotifications,
  updateCandidateSlug,
  updateCandidateResumeFile,
  updateCandidateUpdatedDate,
  updateNotificationCount,
  updateMessageCount
} = {
  ...sessionReducer.actions,
};

// Export reducers
export default sessionReducer.reducer;
