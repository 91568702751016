/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "../../components/Jobs/Card";
import {
  AsyncSelect,
  Button,
  SelectNew as Select,
} from "../../components/common";
import NotFoundPage from "../../components/common/NotFoundPage";
import useQuery from "../../hooks/useQuery";
import useSessionUser from "../../hooks/useSessionUser";
import { rootReducersState } from "../../reducers";
import {
  jobListRequest,
  jobListReset,
  publicJobListRequest,
} from "../../reducers/job/jobs.reducer";
import appRoutes from "../../routes/app.routes";
import commonServices from "../../services/common.services";
import {
  REMOTE_CHOICE,
  jobTypes,
  timeDurations,
} from "../../utils/appConstants";
import { getTokenUser } from "../../utils/appUser";
import JobLoading from "./JobLoading";

const JOB_TYPE_OPTIONS = [
  { label: jobTypes.ALL.title, value: `${jobTypes.ALL.key}` },
  { label: jobTypes.FULL_TIME.title, value: `${jobTypes.FULL_TIME.key}` },
  { label: jobTypes.PART_TIME.title, value: `${jobTypes.PART_TIME.key}` },
  { label: jobTypes.CONTRACT.title, value: `${jobTypes.CONTRACT.key}` },
  { label: jobTypes.INTERNSHIP.title, value: `${jobTypes.INTERNSHIP.key}` },
];

const JOB_DURATION_OPTIONS = [
  {
    label: _.capitalize(timeDurations.ALL.title),
    value: `${timeDurations.ALL.key}`,
  },
  {
    label: _.capitalize(timeDurations.MONTH.title),
    value: `${timeDurations.MONTH.key}`,
  },
  {
    label: _.capitalize(timeDurations.WEEK.title),
    value: `${timeDurations.WEEK.key}`,
  },
];

const remoteOnSiteOptions = [
  {
    value: "0",
    label: "All",
  },
  ...REMOTE_CHOICE,
];

const defaultFilter = {
  page: 1,
  pageSize: 12,
  jobType: jobTypes.ALL.key,
  jobDuration: timeDurations.ALL.key,
  easyApply: false,
  isRemote: "0",
  location: "",
};

let searchDelay: any = null;

const JobSearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const queryPrams = useQuery();
  const tmpSearchTerm = queryPrams.get("q");

  const { jobs } = useSelector(({ job }: rootReducersState) => job);
  const { IS_COMPANY } = useSessionUser();
  const { control } = useForm();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(defaultFilter);
  /* Check if user is already logged in */
  const tokenData = getTokenUser();
  const role = _.get(tokenData, "role", "");

  const pagination = _.get(jobs, "pagination", {});
  const jobPostings = _.get(jobs, "data", []);
  const loading = _.get(jobs, "loading", true);
  const [isFetching, setIsFetching] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [easyApplyFlag, setEasyApplyFlag] = useState(false);

  // When the filter state updated, we will dispatch an action
  useEffect(() => {
    !_.isEmpty(tokenData) && role === "candidate"
      ? dispatch(jobListRequest(filter))
      : dispatch(publicJobListRequest(filter));
    setIsFetching(false);

    // eslint-disable-next-line
  }, [filter]);

  // reset to current response
  useEffect(() => {
    dispatch(jobListReset(filter));
    setIsFetching(false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  }, []);
  // Search only if dependence data changed
  useEffect(() => {
    // dispatch(clearAllRequest());
    setInitialLoading(true);
    clearTimeout(searchDelay);
    searchDelay = setTimeout(async () => {
      const param = { ...filter, page: 1, q: tmpSearchTerm };
      setPage(1);
      setFilter(param);
      setInitialLoading(false);
    }, 1000);
  }, [tmpSearchTerm]);
  const _handleJobTypeOption = (type: any) => {
    const param = { ...filter, page: 1, jobType: type.value };
    setPage(1);
    setFilter(param);
    setInitialLoading(true);
    dispatch(jobListReset({ ...filter, page, jobType: type }));
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  };

  const _handleJobPostedOption = (_duration: any) => {
    // Once the filter will update, action will be dispatched automatically
    setPage(1);
    setFilter({
      ...filter,
      page: 1,
      jobDuration: _duration.value,
    });
    dispatch(jobListReset({ ...filter, page, jobDuration: _duration.value }));
    setInitialLoading(true);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  };

  const _handleEasyApply = () => {
    setEasyApplyFlag(!easyApplyFlag);
    const param = { ...filter, page: 1, easyApply: !easyApplyFlag };
    setPage(1);
    setFilter(param);
    setInitialLoading(true);
    dispatch(jobListReset({ ...filter, page, easyApply: !easyApplyFlag }));
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  };
  const _handleJobLocationOption = (location: any) => {
    setPage(1);
    setFilter({
      ...filter,
      page: 1,
      isRemote: location.value,
    });
    dispatch(jobListReset({ ...filter, page, isRemote: location.value }));
    setInitialLoading(true);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  };
  // const _handleSearch = (q: string) => {
  //   setInitialLoading(true);
  //   clearTimeout(searchDelay);
  //   searchDelay = setTimeout(async () => {
  //     const param = { ...filter, page: 1, q: q };
  //     setPage(1);
  //     setFilter(param);
  //     setInitialLoading(false);
  //   }, 1000);
  // };

  const _handleJobHubRoute = () =>
    history.push(appRoutes.candidateJobHub.generatePath("saved"));

  // Load more data on scroll
  window.onscroll = _.debounce(() => {
    const docBottom =
      document.documentElement.getBoundingClientRect().bottom - 1200;
    const reachedBottom = window.innerHeight > docBottom;

    if (reachedBottom && !isFetching && !loading && page < pagination.total) {
      setPage((prevPage) => prevPage + 1);
      setFilter({
        ...filter,
        page: page + 1,
      });
      setIsFetching(true);
    }
  }, 100);

  const _handleChangeLocationFilter = (loc: any) => {
    setPage(1);
    setFilter({
      ...filter,
      page: 1,
      location: loc?.value || null,
    });
    dispatch(
      jobListReset({ ...filter, page, location: loc?.value || undefined })
    );
    setInitialLoading(true);
    setTimeout(() => {
      setInitialLoading(false);
    }, 1000);
  };

  // Search location with delay of 1 second
  const searchLocations = (search: string) =>
    new Promise((resolve) => {
      clearTimeout(searchDelay);
      searchDelay = setTimeout(async () => {
        const locationData = await commonServices.fetchLocationsPublic(search);
        const locations = _.get(locationData, "data.data", []).map((c) => ({
          value: c.id,
          label: c.location,
        }));

        resolve(locations);
      }, 1000);
    });

  return (
    <div className="max-container">
      <div className="job-search-wrapper">
        <div className="filter-wrapper">
          <div className="filter-row">
            {/* <SearchBar handleSearch={_handleSearch} /> */}
            <p className="total-result">
              About {pagination?.totalResult || 0} results
            </p>
            {!IS_COMPANY && (
              <Button
                onClick={_handleJobHubRoute}
                variant="contained"
                color="primary"
              >
                MY JOB HUB
              </Button>
            )}
          </div>
          <span className="separator" />
          <div className="filter-row filter-row-wrapper">
            <div className="select-item ncustom-dropdown">
              <Select
                name="recent"
                value={JOB_DURATION_OPTIONS.find(
                  (recent) => recent.value === filter.jobDuration
                )}
                options={JOB_DURATION_OPTIONS}
                placeholder="Select one"
                onChange={_handleJobPostedOption}
                externalLabel={{ label: "Relevant/Recent" }}
              />
            </div>
            <div className="select-item ncustom-dropdown">
              <AsyncSelect
                name="city_id"
                placeholder="Search City, State or country..."
                externalLabel={{
                  label: "Location",
                }}
                onStartSearching={searchLocations}
                control={control}
                noOptionsMessage="No matching locations found"
                handleCustomOnChange={_handleChangeLocationFilter}
                isClearable={true}
              />
            </div>
            <div className="select-item ncustom-dropdown">
              <Select
                name="recent"
                value={remoteOnSiteOptions.find(
                  (recent) => recent.value === filter.isRemote
                )}
                options={remoteOnSiteOptions}
                placeholder="Select one"
                onChange={_handleJobLocationOption}
                externalLabel={{ label: "Location Type" }}
              />
            </div>
            <div className="select-item ncustom-dropdown">
              <Select
                name="recent"
                value={JOB_TYPE_OPTIONS.find(
                  (recent) => recent.value === filter.jobType
                )}
                options={JOB_TYPE_OPTIONS}
                placeholder="Select one"
                onChange={_handleJobTypeOption}
                externalLabel={{ label: "Job Type" }}
              />
            </div>
            <div
              className={
                !easyApplyFlag ? "easy-apply-btn" : "easy-apply-btn-filled"
              }
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={_handleEasyApply}
              >
                Easy Apply
              </Button>
            </div>
          </div>
        </div>
        {initialLoading ? (
          <div className="list-wrapper">
            <JobLoading />
          </div>
        ) : (
          <div className="list-wrapper">
            {_.isEmpty(jobPostings) ? (
              <NotFoundPage />
            ) : (
              jobPostings.map((job) => <Card key={job.id} job={job} />)
            )}
          </div>
        )}

        {(isFetching || loading) && (
          <div className="see-more circle-center">
            <CircularProgress color="secondary" size={25} />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobSearch;
