import React from "react"
import { Switch } from "react-router-dom"
import RoutesContainer from "./RoutesContainer"
import appRoutes from "./app.routes"
//import CompanyLayout from "../pages/layouts/Company";
import CompanyLayout from "../Layouts/CompanyLayout"
import DefaultLayoutNew from "../Layouts/DefaultLayout"

import CompanyDashboard from "./../pages/Dashboard/CompanyDashboard"
// import Candidates from "./../pages/Candidates";
//import Jobs from "./../pages/Jobs";
import Jobs from "./../pages/CompanyJobs"
import Page from "./../pages/Page"
//import People from "./../pages/People";
import People from "./../pages/CompanyPeople"
import Messages from "./../pages/Messages"
// import Settings from "./../pages/Settings";
// import CandidateProfile from "./../pages/CandidateProfile";
import CandidateProfileView from "./../pages/CandidateProfile/Profile";
import searchAndConnect from "./../pages/SearchAndConnect";
import JobsGrid from "./../pages/Jobs/components/grid";
import CandidateVideos from "../pages/videoStudio/index";
import CandidateCreateVideo from "../pages/videoStudio/components/CreateVideo";
import CompanyCandidates from "../pages/CompanyAdminPages/CompanyCandidates/index";
import CompanyAdmins from "./../pages/CompanyAdmins";
import MarketingSolution from "./../pages/MarketingSolution";
import CompanyNews from "./../pages/News";
import CompanyEvents from "./../pages/Events";
import CandidateConnections from "./../pages/CandidateConnections";
import ConnectionNotifications from "./../pages/CandidateNotifications";
import JobHub from "../pages/JobHub";
import FeedPost from "../pages/Feeds";
import FeedDetails from "../pages/Feeds/FeedDetail";
import MyNetwork from "../pages/MyNetwork";
import MyMeetings from "../pages/MyMeetings";
import Scheduler from "./../pages/Scheduler";
import CandidateSettings from "../pages/CandidateSettings";
import AddTags from "../pages/MarketingSolution/components/addTags"
import ReviewInfo from "../pages/MarketingSolution/components/reviewInfo";
import ContactList from "../pages/MarketingSolution/components/list";
import HashTagFeed from "../pages/Feeds/HashtagPost";
import Article from "../pages/Article/UpsetArticle";
import ArticleView from "../pages/Article/components/Fetch";
// import NotFoundError from "../pages/Errors/NotFoundError";

const routes = [
  {
    path: appRoutes.companyDashboard.path,
    exact: true,
    component: CompanyDashboard,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidates.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateFavorite.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateQualified.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateInterviewed.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateHired.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateRejected.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateContacted.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyCandidateScreening.path,
    exact: true,
    component: CompanyCandidates,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyJobs.path,
    exact: true,
    component: Jobs,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyActiveJobs.path,
    exact: true,
    component: Jobs,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyJobsArchive.path,
    exact: true,
    component: Jobs,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyJobsDrafts.path,
    exact: true,
    component: Jobs,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPage.path,
    exact: true,
    component: Page,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPageHome.path,
    exact: true,
    component: Page,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPagePeople.path,
    exact: true,
    component: People,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPageJobs.path,
    exact: true,
    component: JobsGrid,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPageNews.path,
    exact: true,
    component: CompanyNews,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyPageEvents.path,
    exact: true,
    component: CompanyEvents,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyAdmins.path,
    exact: true,
    component: CompanyAdmins,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.marketingSolution.path,
    exact: true,
    component: MarketingSolution,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.uploadContact.path,
    exact: true,
    component: MarketingSolution,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.createTags.path,
    exact: true,
    component: AddTags,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.reviewInfo.path,
    exact: true,
    component: ReviewInfo,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.contactList.path,
    exact: true,
    component: ContactList,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.manuallyContact.path,
    exact: true,
    component: MarketingSolution,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  {
    path: appRoutes.companyMessages.path,
    exact: true,
    component: Messages,
    layout: CompanyLayout,
    access: { level: "company" },
  },
  // {
  //   path: appRoutes.companyMessageCandidates.path,
  //   exact: true, component: Messages, layout: CompanyLayout, access: { level: "company" }
  // },
  // {
  //   path: appRoutes.companyMessageAdmins.path,
  //   exact: true, component: Messages, layout: CompanyLayout, access: { level: "company" }
  // },
  // {
  //   path: appRoutes.companyMessageMessageRequests.path,
  //   exact: true, component: Messages, layout: CompanyLayout, access: { level: "company" }
  // },
  // {
  //   path: appRoutes.companySettings.path,
  //   exact: true, component: Settings, layout: CompanyLayout, access: { level: "company" }
  // },
  // {
  // 	path: appRoutes.companyBasicInfo.path,
  // 	exact: true, component: CompanyBasicInfo, layout: BasicLayout, access: { level: "company" }
  // },
  {
    path: appRoutes.scheduler.path,
    exact: true,
    component: Scheduler,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  // TODO - remove all the related component
  // {
  //   path: appRoutes.candidateProfile.path,
  //   exact: true,
  //   component: CandidateProfile,
  //   layout: DefaultLayoutNew,
  //   access: { level: "candidate" },
  // },
  {
    path: appRoutes.searchAndConnect.path,
    exact: true,
    component: searchAndConnect,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateProfileView.path,
    exact: true,
    component: CandidateProfileView,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateVideos.path,
    exact: true,
    component: CandidateVideos,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateCreateVideo.path,
    exact: true,
    component: CandidateCreateVideo,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateEditVideo.path,
    exact: true,
    component: CandidateCreateVideo,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateConnections.path,
    exact: true,
    component: CandidateConnections,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateConnectionsType.path,
    exact: true,
    component: CandidateConnections,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.connectionNotifications.path,
    exact: true,
    component: ConnectionNotifications,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.companyNotifications.path,
    exact: true,
    component: ConnectionNotifications,
    layout: DefaultLayoutNew,
    access: { level: "company" },
  },
  {
    path: appRoutes.candidateMessages.path,
    exact: true,
    component: Messages,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateMyMeetings.path,
    exact: true,
    component: MyMeetings,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateProfileSettings.path,
    exact: true,
    component: CandidateSettings,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.candidateJobHub.path,
    exact: true,
    component: JobHub,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.Feed.path,
    exact: true,
    component: FeedPost,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.FeedDetails.path,
    exact: true,
    component: FeedDetails,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.HashTagFeed.path,
    exact: true,
    component: HashTagFeed,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.MyNetwork.path,
    exact: true,
    component: MyNetwork,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.article.path,
    exact: true,
    component: Article,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },
  },
  {
    path: appRoutes.articleUpdate.path,
    exact: true,
    component: Article,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },

  },
  {
    path: appRoutes.getArticle.path,
    exact: true,
    component: ArticleView,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },

  },
  {
    path: appRoutes.viewArticle.path,
    exact: true,
    component: ArticleView,
    layout: DefaultLayoutNew,
    access: { level: "candidate" },

  },

  // {
  //   path: appRoutes.Error404.path,
  //   exact: true,
  //   component: NotFoundError,
  //   layout: DefaultLayoutNew,
  //   private: false,
  // },
]

const PrivateRoute = () => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RoutesContainer
          key={i}
          // private={route?.private ? false : true}
          private={true}
          {...route}
        />
      ))}
    </Switch>
  )
}

export default PrivateRoute
