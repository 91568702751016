import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { ReactComponent as Logo } from "./../../assets/svg/Logo.svg"
import MobileLogo from "./../../assets/svg/EH-logo-mobile.png"
import { ReactComponent as DashboardIcon } from "./../../assets/svg/company-dashboard-new.svg"
import { ReactComponent as CandidateIcon } from "./../../assets/svg/company-candidate-new.svg"
import { ReactComponent as AdminIcon } from "./../../assets/svg/company-admin-new.svg"
import { ReactComponent as JobIcon } from "./../../assets/svg/company-job-new.svg"
import { ReactComponent as PageIcon } from "./../../assets/svg/company-page-new.svg"
import { getCurrentTab } from "../../utils/helper";
import appRoutes from "../../routes/app.routes";
import { rootReducersState } from "../../reducers";
import { useSelector } from "react-redux";
import useSessionUser from "../../hooks/useSessionUser";
import { commpanyStatus as companyStatus } from "../../utils/CompanyAdminAccess";
import { IS_DEVELOPMENT } from "../../utils/appConstants";
import MessageIcon from "@material-ui/icons/Message";
import { UserAvatar } from "../../components/common"



interface CompanySideBarProps {
  children?: any
}

const CompanySidebar = (prop: CompanySideBarProps) => {
  const { children } = prop
  const { currentCompanyLogo } = useSessionUser()
  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session
  )
  const companySlug = sessionReducer.currentCompany.slug
  const currentSubTab = getCurrentTab(children)
  const companyAccess = sessionReducer.currentCompany

  useEffect(() => {
    companyStatus(companyAccess)
  }, [companyAccess])


  return (
    <div className="sidebar-wrap">
      <div className="sidebar-logo">
        <div className="desktop-logo">
          <Logo />
        </div>
        <div className="mobile-logo">
          <img src={MobileLogo} alt="MobileLogo" />
        </div>
      </div>
      <div className="sidebar-menu-wrap">
        <ul className="side-menu top">
          {(companyStatus(companyAccess) === "admin" ||
            companyStatus(companyAccess) === "sale_recruiter" ||
            companyStatus(companyAccess) === "marketing") && (
              <li>
                <Link
                  key="link_dashboard"
                  to={appRoutes.companyDashboard.generatePath(companySlug)}
                  className={`dashboard-menu ${currentSubTab === "dashboard" ? "active" : ""
                    }`}
                >
                  <DashboardIcon />
                  <span>Dashboard</span>
                </Link>
              </li>
            )}
          {(companyStatus(companyAccess) === "admin" ||
            companyStatus(companyAccess) === "sale_recruiter") && (
              <li>
                <Link
                  key="link_candidates"
                  to={appRoutes.companyCandidates.generatePath(companySlug)}
                  className={`candidate-menu ${currentSubTab === "candidates" ? "active" : ""
                    }`}
                >
                  <CandidateIcon />
                  <span>Candidates</span>
                </Link>
              </li>
            )}
          {(companyStatus(companyAccess) === "admin" ||
            companyStatus(companyAccess) === "sale_recruiter") && (
              <li>
                <Link
                  key="link_jobs"
                  to={appRoutes.companyActiveJobs.generatePath(companySlug)}
                  className={`job-menu ${currentSubTab === "jobs" ? "active" : ""
                    }`}
                >
                  <JobIcon />
                  <span>Jobs</span>
                </Link>
              </li>
            )}
          {companyStatus(companyAccess) === "admin" && (
            <li>
              <Link
                key="link_page"
                to={appRoutes.companyPage.generatePath(companySlug)}
                className={`page-menu ${currentSubTab === "page" ? "active" : ""
                  }`}
              >
                <PageIcon />
                <span>Page</span>
              </Link>
            </li>
          )}
          {companyStatus(companyAccess) === "admin" && (
            <li>
              <Link
                key="link_admin"
                to={appRoutes.companyAdmins.generatePath(companySlug)}
                className={`admin-menu ${currentSubTab === "admins" ? "active" : ""
                  }`}
              >
                <AdminIcon />
                <span>Admins</span>
              </Link>
            </li>
          )}
          {IS_DEVELOPMENT && companyStatus(companyAccess) === "admin" && (
            <li>
              <Link
                key="marketing-solution"
                to={appRoutes.marketingSolution.generatePath(companySlug)}
                className={`admin-menu ${currentSubTab === "marketing-solution" ? "active" : ""
                  }`}
              >
                <AdminIcon />
                <span>Marketing</span>
                <span>Solution</span>
              </Link>
            </li>
          )}
          {companyStatus(companyAccess) === "admin" && (
            <li>
              <Link
                key="messages"
                to={appRoutes.companyMessages.generatePath(companySlug)}
                className={`admin-menu ${currentSubTab === "messages" ? "active" : ""
                  }`}
              >
                <MessageIcon />
                <span>Messages</span>
              </Link>
            </li>
          )}
          <li>
            <UserAvatar
              className="company-logo"
              size="xsm"
              src={currentCompanyLogo}
            />
          </li>
        </ul>

        {/* <ul className="side-menu bottom">
            <li>
              <Link key="link_setting" to={appRoutes.companySettings.generatePath(companySlug)} className={`setting-menu ${ currentSubTab === 'settings' ? 'active' : '' }`}>
                <SettingIcon/>
                <span>Setting</span>
              </Link>
            </li>
          </ul> */}
      </div>
    </div>
  )
}

export default CompanySidebar
