/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { InputAdornment } from "@material-ui/core";
import _ from "lodash";
import LogRocket from "logrocket";
import Smartlook from "smartlook-client";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Input } from "../../../components/common";
import {
  userLoginRequest,
  resetLoginFlag,
} from "../../../reducers/auth/login.reducer";
import {
  setCurrentCompanyRequest,
  setSessionTokenRequest,
  setSessionUserRequest,
} from "../../../reducers/auth/session.reducer";
import appRoutes from "../../../routes/app.routes";
import { ReactComponent as PersonIcon } from "../../../assets/svg/person.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock.svg";
import Affirmation from "../../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { COMPANY_FLAG } from "../../../utils/appConstants";

type Inputs = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const logRocketAppId = process.env.REACT_APP_LOG_ROCKET;
  const smartLookAppId = process.env.REACT_APP_SMART_LOOK;

  const { executeRecaptcha: executeReCaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    message: loginError,
    flag: loginFlag,
    data,
  } = useSelector(({ auth }: any) => auth.login);

  const { register, handleSubmit, errors } = useForm<Inputs>();

  // Verify the captcha
  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeReCaptcha) {
        console.log("Execute recaptcha not yet available");
        return false;
      }

      return await executeReCaptcha("login");
    } catch (error) {
      return "";
    }
  }, [executeReCaptcha]);

  useEffect(() => {
    if (loginFlag === true) {
      const token = _.get(data, "token", null);
      const sessionData = _.get(data, "session", {});

      // Set session data
      dispatch(setSessionTokenRequest(token));
      dispatch(setSessionUserRequest(sessionData));

      // Navigate user based on role
      const IS_COMPANY =
        _.get(data, "session.is_company") === COMPANY_FLAG.YES ? true : false;
      let companyDetails =
        sessionData?.companies?.find((row) => row.privileges === true) || null;

      // Check for first company access
      if (!companyDetails) {
        companyDetails = sessionData?.companies[0] || {};
      }

      const companySlug = companyDetails.slug || "";
      const userId = sessionData.id;
      const email = sessionData.email;
      const firstName = sessionData.first_name;

      if (IS_COMPANY && companySlug) {
        // LogRocket User Identity
        if (logRocketAppId) {
          LogRocket.identify(logRocketAppId, {
            name: firstName,
            email: email,
            companySlug: companySlug,
          });
        }

        // Smartlook User Identity
        if (smartLookAppId) {
          Smartlook.identify(userId, {
            name: firstName,
            email: email,
            companySlug: companySlug,
          });
        }

        // Set the company
        dispatch(setCurrentCompanyRequest(companyDetails));
        const companyPath =
          appRoutes.companyDashboard.generatePath(companySlug);
        history.push(companyPath);
      } else {
        // LogRocket User Identity
        if (logRocketAppId) {
          LogRocket.identify(logRocketAppId, {
            name: firstName,
            email: email,
          });
        }

        // Smartlook User Identity
        if (smartLookAppId) {
          Smartlook.identify(userId, {
            name: firstName,
            email: email,
          });
        }
        history.push(appRoutes.candidateProfileView.path);
      }

      dispatch(resetLoginFlag());
    }
  }, [loginFlag, data, dispatch, history]);

  const onSubmit = async (formData) => {
    const captchaToken = await handleReCaptchaVerify();

    dispatch(
      userLoginRequest({
        email: formData.email,
        password: formData.password,
        token: captchaToken,
        action: "login",
      })
    );
  };

  return (
    <>
      <div className="left-part">
        <Affirmation />
      </div>
      <div className="right-part">
        <div className="">
          <div className="back-website">
            <div className="back">
              <Link to={appRoutes.home.path}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>
            </div>
            <Link to={appRoutes.home.path}>Back to website</Link>
          </div>
          <div className="auth-card auth-login-wrapper">
            <h2 className="auth-card-title">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <p className="form-group-label">Email</p>
                <Input
                  name="email"
                  placeholder="Email"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter email address",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Enter valid email address",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <p className="form-group-label">Password</p>
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                    minLength: {
                      value: 4,
                      message: "Password must be between 4 to 16 characters",
                    },
                  })}
                />
                {loginError && (
                  <span className="text-sm text-danger error-helper">
                    {loginError}
                  </span>
                )}
              </div>
              <div className="form-group btn-group">
                <Button type="submit" color="primary" loading={loading}>
                  Login
                </Button>
              </div>
              <div className="form-group text-center">
                <Link to={appRoutes.userForgotPassword.path}>
                  {appRoutes.userForgotPassword.title}
                </Link>
              </div>
            </form>
          </div>
          <p className="outer-link">
            If you don’t have an account?{" "}
            <Link to={appRoutes.userSignUp.path}>
              {appRoutes.userSignUp.title}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
