import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, FlashMessage, Input, Modal } from "../../components/common";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid } from "@material-ui/core";
import authServices from "../../services/auth.services";
import List from "./components/List";
import _ from "lodash";
import { rootReducersState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateCandidateUpdatedDate } from "../../reducers/auth/session.reducer";

type FormInputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  company_logo: string;
  banner_image: string;
};

const SecurityLogin = () => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset: resetForm,
    setError,
  } = useForm<FormInputs>();

  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState<boolean>(false);
  const [viewAllSessions, setViewAllSessions] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState<Array<object>>([]);
  const [currentlySigned, setCurrentlySigned] = useState<number>(0);
  const [sessionId, setSessionId] = useState<string>("");
  const [password, setPassword] = useState<boolean>(false);
  const {
    handleSubmit: handlePasswordSubmit,
    register: registerPassword,
    errors: errorsPassword,
  } = useForm({});

  const sessionReducer = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );

  useEffect(() => {
    fetchUserSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserSessions = async () => {
    try {
      const sessionsData = await authServices.getUserSessions();

      const sessionData = _.get(sessionsData, "data.rows", []);

      // eslint-disable-next-line array-callback-return
      sessionData.reduce((accumulator: any, curValue: any) => {
        if (
          accumulator?.active_session === 1 ||
          curValue?.active_session === 1
        ) {
          setCurrentlySigned((currentlySigned) => currentlySigned + 1);
        }
      });

      setSessionData(sessionData);
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const currentMatchingPassword = watch("newPassword", "");

  const _handleClosePasswordChangeModal = () => {
    resetForm({});
  };

  const handleChange = () => {
    setSelectedValue(!!!selectedValue);
  };

  const onSubmit = async (formData: FormInputs) => {
    try {
      const resp = await authServices.changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
        requireAll: selectedValue,
      });

      if (resp.flag) {
        FlashMessage(_.get(resp, "message"), "success");
        setSelectedValue(false);
        const currentDate = moment().format("YYYY-MM-DD hh:mm:ss");
        dispatch(updateCandidateUpdatedDate(currentDate));
        resetForm({});
        if (selectedValue) {
          await filterSessions(_.get(resp, "data"));
        }
      } else {
        if (resp?.errors?.newPassword) {
          setError("newPassword", {
            type: "server",
            message: resp.errors.newPassword,
          });
        }
        if (resp?.errors?.currentPassword) {
          setError("currentPassword", {
            type: "server",
            message: resp.errors.currentPassword,
          });
        }
      }
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const filterSessions = (id) => {
    sessionData.forEach((item) => {
      if (_.get(item, "id") !== id) {
        item["active_session"] = 0;
      }
    });

    setSessionData(sessionData);
  };

  const handleLogout = (id) => {
    setSessionId(id);
    setPassword(true);
  };

  const passwordSubmit = async (formData) => {
    try {
      const resp = await authServices.updateSession({
        sessionId: sessionId,
        password: _.get(formData, "currentPassword", ""),
      });

      if (resp.flag) {
        FlashMessage(_.get(resp, "message"), "success");
        setPassword(false);

        await filterSessionsData(sessionId);
      } else {
        FlashMessage(_.get(resp, "message"), "error");
      }
    } catch (error) {
      FlashMessage(_.get(error, "message"), "error");
    }
  };

  const filterSessionsData = (id) => {
    sessionData.forEach((item) => {
      if (_.get(item, "id") === id) {
        item["active_session"] = 0;
      }
    });

    setSessionData(sessionData);
  };

  return (
    <div className="profile-container">
      <div>
        <h2 className="title">Security And Login</h2>
        <p>
          <div>Let’s make your account more secure.</div>
          <div>
            We’ re always working on ways to increase safety in our employHer.
            That’s why we look at every account to make sure it’s as secure as
            possible.
          </div>
          <p>You account security : Low</p>
        </p>
      </div>
      <div>
        <h2 className="title">Change Password</h2>
        {_.get(sessionReducer, "password_updated_at") &&
          "Last Updated" +
          moment(_.get(sessionReducer, "password_updated_at")).format(
            " D MMM, YYYY"
          )}
        <p>Your new password must be different from previous used password</p>
        <div className="pt-10">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <Input
                    name="currentPassword"
                    type="password"
                    externalLabel={{ label: "Current password" }}
                    placeholder="Current password"
                    validationObj={errors}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter current password",
                      },
                    })}
                    className="settings-input"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} className="pb-0">
                <Input
                  name="newPassword"
                  type="password"
                  externalLabel={{ label: "New password" }}
                  placeholder="New password"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter new password",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                      message:
                        "Must content at least one upper case, lower case, digit, special character and no white space",
                    },
                  })}
                  className="settings-input"
                />
              </Grid>
              <Grid item xs={6} className="pb-0">
                <Input
                  name="confirmPassword"
                  type="password"
                  externalLabel={{ label: "Confirm password" }}
                  placeholder="Confirm password"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter confirm password",
                    },
                    validate: (value) =>
                      value === currentMatchingPassword ||
                      "The re-entered do not match",
                  })}
                  className="settings-input"
                />
              </Grid>

              <Checkbox
                checked={selectedValue}
                onClick={(e) => {
                  handleChange();
                }}
              />

              <p
                onClick={(e) => {
                  handleChange();
                }}
                className="cursor-pointer"
              >
                Require all devices to sign in with new password
              </p>

              <Grid item xs={8} className="form-actions">
                <Grid item xs={8} container justify="flex-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="text-uppercase"
                  >
                    Change Password
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => _handleClosePasswordChangeModal()}
                    color="secondary"
                    className="text-uppercase"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <hr />
      {/* <div className="mt-20 mb-20">
        <h2 className="title">Two-factor authentication</h2>
        (Last Updated
        {moment(_.get(sessionReducer, "updated_at", "")).format(" D MMM, YYYY")}
        )
        <p>
          Ensure the security of your employHER account by enabling two-step
          verification.
        </p>
        <h4>Mange two-step verification</h4>
        <p>
          With 2-Step Verification, you can add an extra layer of security to
          your account in case your password is stolen. After you set up 2-Step
          Verification, you can sign in to your account with: Your password.
          Your phone.
        </p>
        <h4>Set up two-step verification</h4>
        <p>
          To setting up two-step verification you must have to remember your
          profile current password, by entering current password you can guide
          to enable two-step verification.
        </p>
        <div className="pt-10 pb-10">
          <Grid item xs={6} className="pb-0 verification-wrapper">
            <Input
              name="currentPassword1"
              type="password"
              externalLabel={{ label: "Current password" }}
              placeholder="Current password"
              validationObj={errors}
              className="settings-input"
            />
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6} container justify="flex-end">
            <Button type="submit" color="primary" className="text-uppercase">
              Next
            </Button>
          </Grid>
          <Grid item xs={1} className="text-right form-actions">
            <Button
              onClick={() => _handleClosePasswordChangeModal()}
              color="secondary"
              className="text-uppercase"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div> */}
      {/* <hr /> */}
      <div>
        <Grid container justify="space-between" className="mt-20">
          <Grid item md={10} className="notification-card-left">
            <h5 className="mt-10">Device History</h5>
          </Grid>
          <Grid item md={2} className="d-flex justify-content-between">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setViewAllSessions(true);
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
        <p>
          The locations listed below are an estimate of where IP addresses are
          located in your country, region, and city. Look-up accuracy varies
          based on the provider and device location
        </p>
        <b>You’re Currently Signed in to {currentlySigned} session.</b>
        <List
          data={sessionData}
          size={2}
          handleLogout={(e) => {
            handleLogout(e);
          }}
        />
      </div>
      <Modal
        title="Sessions"
        visible={viewAllSessions}
        className="profile-modal editable-profile-modal"
        onClose={() => setViewAllSessions(false)}
      >
        <List
          data={sessionData}
          size={sessionData.length}
          handleLogout={(e) => {
            handleLogout(e);
          }}
        />
      </Modal>
      <Modal
        title="Password"
        visible={password}
        className="change-pw-modal"
        onClose={() => setPassword(false)}
      >
        <div>
          <form noValidate onSubmit={handlePasswordSubmit(passwordSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} className="pb-0">
                <Input
                  name="currentPassword"
                  type="password"
                  externalLabel={{ label: "Current password" }}
                  placeholder="Current password"
                  validationObj={errorsPassword}
                  inputRef={registerPassword({
                    required: {
                      value: true,
                      message: "Please enter current password",
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12} className="text-right form-actions">
                <Button
                  onClick={() => setPassword(false)}
                  color="secondary"
                  className="text-uppercase"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className="text-uppercase"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SecurityLogin;
