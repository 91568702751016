import _ from "lodash";
import React from "react";
// import { useDispatch } from "react-redux";
import { FlashMessage } from "../../../components/common";
import notificationServices from "../../../services/notification.services";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ArrowDropDownIcon from "@material-ui/icons/MoreHoriz";
import appRoutes from "../../../routes/app.routes";
// import { notificationTypes } from "../../../utils/appConstants";
// import {
//   clearNotificationRequest,
//   notificationListRequest,
// } from "../../../reducers/ConnectionNotification/connectionNotification.reducer";

interface NotificationItemProps {
  notification: any | null;
  handleClear: React.Dispatch<React.SetStateAction<string>>;
  handleRead: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationItem = ({ notification, handleClear, handleRead }: NotificationItemProps) => {
  // const dispatch = useDispatch();
  // const _handleClear = () => {
  //   dispatch(clearNotificationRequest(notification.id));
  //   setTimeout(() => {
  //     dispatch(notificationListRequest({ page: 1, pageSize: 10 }));
  //   }, 1000);
  // };
  console.log('notification', notification);
  const _handleClear = async (id) => {
    try {
      const result = await notificationServices.clearNotification(id);

      if (result.flag) {
        handleClear(id)
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  }

  const _handleRead = async (id) => {
    try {
      const result = await notificationServices.readNotification(id);

      if (result.flag) {
        handleRead(id)
      } else {
        FlashMessage(result.message, "error");
      }
    } catch (error) {
      FlashMessage(error?.message, "error");
    }
  }

  // const fullName = `${_.get(
  //   notification,
  //   `connection.follower.first_name`,
  //   ""
  // )} ${_.get(notification, `connection.follower.last_name`, "")}`;

  const handleOpenPostDetails = () => {
    const slug = _.get(notification, 'post.slug', '');
    const connectionSlug = _.get(notification, 'user.slug', '')
    console.log('connectionSlug', connectionSlug);
    if (!_.isEmpty(slug)) {
      const postLink = appRoutes.FeedDetails.generateFullPath(slug);
      window.open(postLink, '_blank');
    }
    if (!_.isEmpty(connectionSlug)) {
      const connectionLink = appRoutes.candidatePublicProfile.generateFullPath(connectionSlug);
      window.open(connectionLink, "_blank")
    }
  };


  return (
    <div className="connection-item-wrapper">
      <div className="avatar">
        {notification?.is_read === 1 && <span className="notification-dot" />}
      </div>
      <div>
        <div className="details">
          <div>
            <h2 onClick={handleOpenPostDetails}>
              {_.get(notification, "payload")}
            </h2>
          </div>

          {/* <div>
            {_.get(notification, "notification_type_id") !== 0 && <label className={`status`}>
              {notificationTypes.find((row) => row.value === _.get(notification, "notification_type_id"))?.label}
            </label>}
          </div> */}

          <div>
            <Menu
              menuButton={
                <MenuButton style={{ background: "white", border: "none" }}>
                  <ArrowDropDownIcon />
                </MenuButton>
              }
              transition
            >
              {_.get(notification, "is_read") === 1 && <MenuItem onClick={() => { _handleRead(_.get(notification, "id")) }}>Mark As Read</MenuItem>}
              <MenuItem onClick={() => { _handleClear(_.get(notification, "id")) }}>Delete</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
