import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "./../../assets/svg/Logo.svg";

interface Props {
  children: React.ReactChild;
  [key: string]: any;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <div className="auth-layout-container">
        <div className="auth-header">
          <div className="brand-wrapper">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
        <main className="auth-main">{children}</main>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default AuthLayout;
