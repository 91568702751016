import React from "react";
import { ReactComponent as HeartActive } from "../../assets/svg/heart-active.svg";
import { ReactComponent as Comment } from "../../assets/svg/comment.svg";
import _ from "lodash";
import appRoutes from "../../routes/app.routes";
import NotFoundPage from "../../components/common/NotFoundPage";
import { openUrlInNewTab } from "../../utils/helper";

interface candidateFeedProps {
  feed: [];
  publicPage?: boolean;
}

interface postTypes {
  id: string;
  content: string;
  post_comments: [];
  post_comments_count: number;
  post_likes_count: number;
  post_media: undefined | string;
  slug: string;
}

const Feed: React.FC<candidateFeedProps> = ({ feed, publicPage }) => {
  const getFeedItem = (post: postTypes) => {
    return (
      <>
        {!_.isEmpty(post.content) && (
          <div
            className="feed-content-info wysiwyg-data"
            dangerouslySetInnerHTML={{
              __html:
                post.content.length > 200
                  ? post.content.substring(0, 200) + "<span>...</span>"
                  : post.content,
            }}
          />
        )}
        {!_.isEmpty(post.post_media) && (
          <div className="img-wrapper">
            <img src={post.post_media} alt="Feed-thumbnail" />
          </div>
        )}
        <div className="feed-footer">
          <ul>
            <li>
              <HeartActive /> {post.post_likes_count}
            </li>
            <li>
              <Comment /> {post.post_comments_count}
            </li>
          </ul>
        </div>
      </>
    );
  };
  return (
    <div className="feed-content feed-public-wrapper">
      <div className="edit-wrapper">
        <h1>Feeds</h1>
      </div>
      {!_.isEmpty(feed) ? (
        <div className="feed-content-wrapper">
          {!_.isEmpty(feed) &&
            feed.map((post: postTypes) => (
              <div
                className="feeds-card"
                onClick={() => {
                  openUrlInNewTab(
                    appRoutes.FeedDetails.generateFullPath(post?.slug)
                  );
                }}
              >
                {getFeedItem(post)}
              </div>
            ))}
        </div>
      ) : (
        <NotFoundPage content="No feeds found" />
      )}
    </div>
  );
};

export default Feed;
